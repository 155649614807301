import { Service } from './Service'

interface MaintenanceSettings {
  enabled: boolean
  reloadInterval: number
}

const maintenanceSettings: MaintenanceSettings = {
  enabled: process.env.REACT_APP_MAINTENANCE_ENABLED === 'true',
  reloadInterval: 60 * 1000, // 60s
}
export class MaintenanceService implements Service {
  enabled = false
  reloadInterval: number | undefined
  nextRefresh: number | undefined = undefined

  async init(): Promise<void> {
    this.enabled = maintenanceSettings.enabled
    this.reloadInterval = maintenanceSettings.reloadInterval
  }

  async run(): Promise<void> {
    if (!this.enabled || !this.reloadInterval) {
      return
    }

    this.nextRefresh = new Date().valueOf() + this.reloadInterval
    setTimeout(() => {
      window.location.reload()
    }, this.reloadInterval)
  }
}

const maintenanceService = new MaintenanceService()
export default (): MaintenanceService => maintenanceService
