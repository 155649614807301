// https://gist.github.com/acdlite/a68433004f9d6b4cbc83b5cc3990c194 */
import Debug from 'debug'
import { prefix } from 'inline-style-prefixer'
import React from 'react'
import Loadable from 'react-loadable'
import { Button, Loader, Message, Segment } from 'semantic-ui-react'

const debug = Debug('app:utils:CodeSplitting')

function LoadingLoadable(props: any): JSX.Element | null {
  if (props.error) {
    debug('LoadingLoadable : ', props.error)
    return (
      <Segment basic style={{ minHeight: '330px' }}>
        <Message style={{ margin: '50px', textAlign: 'center' }}>
          Oups ! Petit soucis lors du chargement de l'application.
          <br />
          En attendant vous pouvez essayer de recharger la page.
          <br />
          <Button
            color="blue"
            onClick={() => {
              window.location.reload(true)
            }}
          >
            Recharger la page
          </Button>
        </Message>
      </Segment>
    )
  } else if (props.pastDelay) {
    return (
      <div style={prefix({ display: 'flex', alignItems: 'center', justifyContent: 'center' })}>
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active size="big">
            Chargement de l'application...
          </Loader>

          <Button
            color="blue"
            onClick={() => {
              window.location.reload(true)
            }}
          >
            Recharger la page
          </Button>

          <Button size="mini" basic onClick={() => window.location.reload(true)}>
            Trop long ? Rechargez la page
          </Button>
        </Segment>
      </div>
    )
  } else {
    return null
  }
}

function RenderLoadable(loaded: any, props: any): JSX.Element {
  const Component = loaded.default
  return <Component {...props} />
}

export const App = Loadable({
  loader: () => import(/* webpackChunkName: "app" */ './../App'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['app'],
  delay: 300, // 0.3 seconds
})

export const BatchsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "batchsIndex" */ './../containers/batchs/BatchsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['batchsIndex'],
  delay: 300, // 0.3 seconds
})

export const RulesIndex = Loadable({
  loader: () => import(/* webpackChunkName: "rulesIndex" */ './../containers/rules/RulesIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['rulesIndex'],
  delay: 300, // 0.3 seconds
})

export const CampaignsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ './../containers/campaigns/CampaignsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['campaignsIndex'],
  delay: 300, // 0.3 seconds
})
export const AccountIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ '../containers/account/AccountIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['AccountIndex'],
  delay: 300, // 0.3 seconds
})
export const LettersIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ './../containers/letters/LettersIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['LettersIndex'],
  delay: 300, // 0.3 seconds
})
export const ToolsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ '../containers/tools/ToolsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['ToolsIndex'],
  delay: 300, // 0.3 seconds
})
export const TemplatesIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ '../containers/templates/TemplatesIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['TemplatesIndex'],
  delay: 300, // 0.3 seconds
})
export const PostcardsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "campaignsIndex" */ './../containers/postcards/PostcardsIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['PostcardsIndex'],
  delay: 300, // 0.3 seconds
})

export const InvoicesIndex = Loadable({
  loader: () => import(/* webpackChunkName: "invoicesIndex" */ '../containers/invoices/InvoicesIndex'),
  render: RenderLoadable,
  loading: LoadingLoadable,
  modules: ['InvoicesIndex'],
  delay: 300, // 0.3 seconds
})
