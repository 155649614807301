import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Form, Grid, Header, Input, Message, Segment } from 'semantic-ui-react'
import { App } from '../../utils/CodeSplitting'
import { API_URL } from '../../utils/Constants'
import { ServiceContext } from '../ServiceProvider'
import getAxiosResponse from '../../api/api'
import { useHistory } from 'react-router'

const OnBoarding = (): JSX.Element => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { user: userService } = useContext(ServiceContext)
  const history = useHistory()

  useEffect(() => {
    const user = userService.getUser()
    setName(user?.name ?? '')
    setPhone(user?.phone ?? '')
  }, [])

  const submit = async () => {
    try {
      await getAxiosResponse({
        url: `${API_URL}/users/${userService.getUser()._id}`,
        method: 'put',
        data: {
          updatedUser: {
            name: name,
            phone: phone,
          },
        },
      })
      await userService.refreshProfile()
      await userService.refreshUser()
      history.push('/')
    } catch (error: any) {
      if (error.message) {
        setErrorMessage(error.message)
      } else {
        setErrorMessage("Une erreur s'est produite, veuillez recommencer.")
      }
    }
  }

  useEffect(() => {
    App.preload()
  }, [])

  return (
    <Grid centered style={{ marginTop: '60px' }}>
      <Helmet>
        <title>Connexion - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
        <meta name="description" content="Connexion à MySendingBox, la solution d'envoi de courrier et de carte postale." />
      </Helmet>

      <Grid.Column computer="6" mobile="16" tablet="8">
        <Segment attached textAlign="center" raised={false} color={process.env.REACT_APP_WHITE_LABEL !== 'canon_fac_simile' ? 'orange' : undefined} padded>
          <Header as="h2">{'Informations'}</Header>
          <br />
          <Form onSubmit={submit}>
            <Form.Field style={{ textAlign: 'left' }}>
              <label>{'Nom'}</label>
              <Input name="name" type="text" value={name} onChange={(e: any) => setName(e.target.value)} className="m-no-bottom" placeholder={'Nom'} />
            </Form.Field>
            <Form.Field style={{ textAlign: 'left' }}>
              <label>{'Téléphone'}</label>
              <Input name="phone" value={phone} onChange={(e: any) => setPhone(e.target.value)} className="m-no-bottom" placeholder={'Téléphone'} />
            </Form.Field>
            {errorMessage && (
              <Message visible negative>
                {errorMessage}
              </Message>
            )}
            <Button type="submit" color="blue" size="big" fluid>
              {'Envoyer'}
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default OnBoarding
