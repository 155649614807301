import React, { Component } from 'react'
import { Button, Container, Divider, Grid, Header } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class NoMatch extends Component {
  render(): JSX.Element {
    return (
      <div>
        <Helmet>
          <title>404 - Page introuvable - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
          <meta
            name="description"
            content="Cette page n'existe pas ou plus. Retournez à l'accueil pour découvrir MySendingBox, une solution simple, sous forme d'API, d'envoi de courrier postal. Intégrez l'API facilement dans n'importe quel CRM, ERP, site web ou application."
          />

          <meta property="og:title" content="404 - Page introuvable - MySendingBox - Solutions d'envoi de courrier et de carte postale" />

          <meta
            property="og:description"
            content="Cette page n'existe pas ou plus. Retournez à l'accueil pour découvrir MySendingBox, une solution simple, sous forme d'API, d'envoi de courrier postal. Intégrez l'API facilement dans n'importe quel CRM, ERP, site web ou application."
          />
        </Helmet>

        <Container fluid={false} style={{ flex: 1, minHeight: '400px', /* background: 'no-repeat top center url(/img/bg.png)', */ backgroundSize: '75%' }}>
          <Divider section hidden />

          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: '3em' }}>
                  Oups ! Cette page n'existe plus.
                </Header>
                <Header as="h3" className="m-no-top">
                  MySendingBox propose des solutions d'envoi de courrier moderne.
                </Header>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden />
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Button as={Link} to="/" primary content="Retourner sur la page d'accueil" />
              </Grid.Column>
            </Grid.Row>

            <Divider hidden section />
            <Divider hidden section />
          </Grid>
        </Container>
      </div>
    )
  }
}

export default NoMatch
