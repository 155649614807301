import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { axiosService } from '../services/axios'
import { AXIOS_ERROR, ConstantErrorInterface, HTTP_RESPONSE_ERROR, HttpResponseError } from '../utils/errors'

/** *************> api.ts
 * Common methods for API calls
 */

/**
 * *> getAxiosResponse()
 * Common method to make an Axios Request and return the HTTP Response
 * Use the AxiosRequestConfig interface for HTTP Request data
 * See https://github.com/axios/axios#request-config
 * Returns a Promise with Response Schema from Axios
 * See https://github.com/axios/axios#response-schema
 *
 * @param config : AxiosRequestConfig
 * @param err : ConstantErrorInterface. Any custom error from src/utils/errors/errors.config.ts
 * @param instanceName : string. Use the .env AUTH_TYPE by default, but another Axios instance can be forced
 * @returns Promise<AxiosResponse>
 */
const getAxiosResponse = async <T>(
  config: AxiosRequestConfig,
  err: ConstantErrorInterface = HTTP_RESPONSE_ERROR,
  instanceName: string = ''
): Promise<AxiosResponse<T>> => {
  try {
    const targetInstanceName = instanceName || process.env.REACT_APP_AUTH_TYPE || 'oidc'

    const response = await axiosService().getAxiosInstance(targetInstanceName).request(config)
    return response
  } catch (error) {
    const errorResult = error as AxiosError
    if (!errorResult.response) {
      throw new HttpResponseError(AXIOS_ERROR.errorName, AXIOS_ERROR.errorKey, errorResult.message, 500)
    }
    throw new HttpResponseError(
      err.errorName,
      err.errorKey,
      config.url ? `[${config.url}] : ${err.errorMessage}` : err.errorMessage,
      errorResult.response.status,
      errorResult.response.data as Record<string, unknown> | null | undefined,
      errorResult.response.headers
    )
  }
}

export default getAxiosResponse
