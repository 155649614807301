import { AxiosInstance } from 'axios'

/** *************> defaultInterceptor.ts
 * An interceptor is an extra process added to an Axios Instance
 * Default Interceptor defines no extra process
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function defaultInterceptor(instance: AxiosInstance): void {
  // No specific process for default interceptor
}
