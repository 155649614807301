import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import { IntercomAPI } from 'react-intercom'
import { Service } from './Service'

/** *************> oidc.services.ts
 * Service Instance for Identity Provider using OpenID Connect protocol
 * OidcService is a Service class launched by the ServiceProvider
 *
 * HOW TO USE :
 * - Calling the services into a React Component (function) :
 * const oidc = useContext(ServiceContext).oidc;
 * - Calling the services anywhere else :
 * import oidcService, { OidcService } from 'services/oidc.services';
 * const services : OidcService = oidcService();
 *
 * - Properties :
 * -- userManager : userManager instance from oidc-client-ts library
 * -- isAuthenticated : boolean, true if the current user is authenticated
 *
 * - Methods :
 * -- openLoginPage() : Redirect to the IDP Login Page
 * -- getToken() : Returns the current access token
 * -- logout() : Redirect to the IDP Logout confirmation Page
 *
 */
const oidcSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY || '',
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID || '',
  redirect_uri: window.location.href,
}

export class OidcService implements Service {
  userManager: UserManager | undefined

  isAuthenticated = false
  isWaitingForCodeClear = false
  loginPolicyError: { login_method: string; login_idp: string | null } | null = null

  /**
   * @override init()
   * Initialize Service
   * Check if auth code is present and fetch token
   *
   * @return Promise<void>
   */
  async init(): Promise<void> {
    this.userManager = new UserManager(oidcSettings)

    await this.handleSigninRedirect()

    const user = await this.userManager.getUser()
    if (user) {
      this.isAuthenticated = true
    }
  }

  /**
   * @override run()
   * Run Service
   * Redirect to oidc login page if needed
   *
   * @return Promise<void>
   */
  async run(): Promise<void> {
    if (this.userManager) {
      if (!this.isAuthenticated) {
        if (this.shouldRedirectToSignIn()) {
          await this.openSignInPage()
        } else {
          await this.openLoginPage()
        }
      }
    }
  }

  /**
   * *> openLoginPage()
   * Redirect to the IDP Login Page
   *
   * @return Promise<void>
   */
  async openLoginPage(): Promise<void> {
    if (this.userManager) {
      await this.userManager.signinRedirect({
        extraQueryParams: {
          ...this.buildExtraLogInParams(),
        },
      })
    }
  }

  async openSignInPage(): Promise<void> {
    if (this.userManager) {
      await this.userManager.signinRedirect({
        extraQueryParams: {
          register: true,
          ...this.buildExtraSignInParams(),
        },
      })
    }
  }

  /**
   * *> getToken()
   * Return the current access token for the user
   *
   * @throws Error : No access token is available
   * @return Promise<string> : Access token value
   */
  async getToken(): Promise<string> {
    let token
    if (this.userManager) {
      const user = await this.userManager.getUser()
      token = user?.access_token
    }

    if (!token) {
      throw new Error("Access token isn't defined : try to refresh the page")
    }

    return token
  }

  /**
   * *> logout()
   * Redirect to the IDP logout confirmation page
   *
   * @return Promise<void>
   */
  async logout(): Promise<void> {
    await this.preLogout()
    await this.userManager?.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    })
  }

  /**
   * *> handleSigninRedirect()
   * Handle the redirect URI after login success into the IDP
   * If 'code' is within URL GET params, it means that is an IDP redirect URI
   * The method reload current page, removing all GET params from IDP
   *
   * @return Promise<void>
   */
  private async handleSigninRedirect(): Promise<void> {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('code')) {
      await this.userManager?.signinRedirectCallback()
      this.isWaitingForCodeClear = true
      setTimeout(() => {
        window.location.search = ''
      }, 1000)
    }
  }

  private shouldRedirectToSignIn(): boolean {
    return window.location.pathname === '/signup'
  }

  private buildExtraLogInParams(): Record<string, string | number | boolean> {
    const url = new URL(window.location.href)
    const extraParams: Record<string, string | number | boolean> = {}

    const idp = url.searchParams.get('idp')
    if (idp) {
      extraParams.kc_idp_hint = idp
    }

    return extraParams
  }

  private buildExtraSignInParams(): Record<string, string | number | boolean> {
    const url = new URL(window.location.href)
    const extraParams: Record<string, string | number | boolean> = {}

    const brandedFor = url.searchParams.get('branded_for')
    if (brandedFor) {
      extraParams.branded_for = brandedFor
    }

    return extraParams
  }

  private async preLogout() {
    IntercomAPI('shutdown')
  }
}

/** * Service Instance Singleton ** */
const oidcService = new OidcService()
export default (): OidcService => oidcService
