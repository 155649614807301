export const translations = {
  en: {
    'Vous devez ajouter un moyen de paiement pour pouvoir envoyer une carte postale': 'You have to add a payment method to send a postcard.',
    'Vous devez ajouter un moyen de paiement pour faire un envoi.': 'You have to add a payment method to sent your letter',
    'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre envoi groupé.': 'You have to add a payment method to launch your batch.',
    'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre campagne.': 'You have to add a payment method to launch your campaign.',
    'Votre numéro de téléphone est nécessaire.': 'Your phone number is necessary.',
    "Erreur lors de l'envoi de votre demande.": 'Error when sending your request.',
    'Veuillez réessayer.': 'Please retry.',
    'On vous rappelle': 'We call you back',
    Nom: 'Name',
    Société: 'Company',
    Téléphone: 'Phone',
    'Bien reçu, on vous rappelle très vite.': 'Received, we call you back soon.',
    Valider: 'Submit',
    Ou: 'Or',
    'Appelez-nous au': 'Call-us at',
    insufficient_right: 'You don’t have sufficient right access.',
    Actualiser: 'Refresh',
    Coût: 'Cost',
    estimation: 'estimation',
    Affranchissement: 'Postage type',
    Service: 'Service',
    "Besoin d'aide ?": 'Need help ?',
    'Lancer la facturation': 'Start Billing',
    'Voulez-vous vraiment lancer la facturation ?': 'Do you really want to start billing ?',
    Non: 'No',
    'Oui. Lancer la facturation.': 'Yes. Start billing.',
    Général: 'Dashboard',
    "Vue d'ensemble": 'Overview',
    Statistiques: 'Stats',
    Invoicing: 'Invoicing',
    'Simulateur de prix': 'Price simulator',
    Services: 'Services',
    Lettres: 'Letters',
    Historique: 'History',
    'Envoyer une nouvelle lettre': 'Send a new letter',
    'Rechercher un courrier': 'Search a letter',
    'Cartes Postales': 'Postcards',
    'Envoyer une nouvelle carte postale': 'Send a new postcard',
    Campagnes: 'Campaigns',
    'Envois groupés': 'Batches',
    Outils: 'Tools',
    Templates: 'Templates',
    'Règles de transformation': 'Transformation rules',
    'Débogueur de webhook': 'Webhook Debugger',
    Paramètres: 'Settings',
    Compte: 'Account',
    Equipe: 'Team',
    Profil: 'User',
    Facturation: 'Billing',
    API: 'API',
    'Clés et Webhook': 'Keys & Webhook URL',
    'Données personnelles': 'Personal Data',
    'Nom du fichier': 'Filename',
    Voir: 'Open',
    'Page {start} à {end}': 'Page {start} to {end}',
    'Ligne 1': 'Line 1',
    'Ligne 2': 'Line 2',
    'Ligne 3': 'Line 3',
    'Code Postal': 'Postal code',
    Ville: 'City',
    Pays: 'Country',
    Précedent: 'Previous',
    Suivant: 'Next',
    'Veuillez glissez-déposez des fichiers PDF': 'Drag and drop some PDF files',
    'Chargement...': 'Loading…',
    sur: 'on',
    fichiers: 'files',
    'Vous devez sélectionner une règle de transformation.': 'You have to select a transformation rule.',
    'Vous devez sélectionner un pays par défaut.': 'You have to select a default country.',
    "Vous devez indiquer le nom ou la société de l'expéditeur.": 'You have to select the name or the company of the sender.',
    "Vous devez indiquer l'adresse de l'expéditeur.": 'You have to select the address of the sender.',
    "Vous devez indiquer le code postal de l'expéditeur.": 'You have to select the postal code of the sender.',
    "Vous devez indiquer la ville de l'expéditeur.": 'You have to select the city of the sender.',
    "Vous devez indiquer le pays de l'expéditeur.": 'You have to select the country of the sender.',
    "Les mots clés de recherche n'ont pas été détectés sur la 1er page du document.": 'The search keywords were not found on the first page of the document.',
    'Les fichiers ajoutés correspondent-ils à la règle utilisée ?': 'Do the added files match the rule used ?',
    "Les mots clés de recherche n'ont pas été détectés sur la dernière page du document.":
      'The search keywords were not found on the last page of the document.',
    'La structure du fichier est invalide.': 'File structure is invalid.',
    "Est-ce bien un fichier PDF ? N'est-il pas corrompu ?": 'Is this a PDF file? Is he not corrupt?',
    'Erreur lors de la prévisualisation avant envoi.': 'Error during preview.',
    'La règle est-elle correctement paramétrée ?': 'Is the rule correctly set ?',
    "Cet envoi groupé n'existe pas": 'This batch doesn’t exists',
    'Nouvel envoi groupé': 'New batch',
    'Règle de transformation': 'Transformation rule',
    Modifier: 'Edit',
    'Choisissez une règle de transformation à appliquer': 'Choose a transformation rule',
    Règle: 'Rule',
    'Créer une nouvelle règle de transformation': 'Create a new transformation rule',
    'Nouvelle règle': 'New rule',
    'Choisissez une 2 ème règle de transformation à appliquer': 'Choose a second transformation rule',
    'Regrouper les documents qui ont le même destinataire dans un seul courrier': 'Regroup document who have the same recipient in one letter',
    Oui: 'Yes',
    "Options d'envoi": 'Sending parameters',
    'Pays du destinataire si non lu sur le PDF': 'Recipient country if not read on the PDF',
    Expéditeur: 'Sender',
    "Ligne d'adresse 1": 'Address line 1',
    "Ligne d'adresse 2": 'Address line 2',
    "Les paramètres d'envoi par défaut de la règle {n} ont été appliqués.": 'Default parameters for the rule {n} have been applied.',
    Options: 'Settings',
    Couleur: 'Color',
    'Recto / verso': 'Both sides',
    "Position de l'addresse": 'Address placement',
    'Première page du document': 'First page',
    'Ajouter une page blanche': 'Add a blank page',
    'Vitesse de remise en poste': 'Postage speed',
    "Plus d'options": 'More options',
    "Fenêtre d'enveloppe": 'Envelope window',
    Simple: 'Simple',
    Double: 'Double',
    "Type d'enveloppe": 'Envelope type',
    'C6 (Petite)': 'C6 (Small)',
    'C4 (Grande)': 'C4 (Big)',
    'Nombre correspondant à des pixels': 'Number representing pixels',
    'Marge à appliquer autour du document': 'Margin to applied around the document',
    'Ajouter une pièce jointe à chaque courrier': 'Add an attachment to every letter',
    'Position de la pièce jointe': 'Attachment position',
    'Dernière page du document': 'At the end of the letter',
    "Date d'envoi en impression - DD/MM/YYYY": 'Send date - DD/MM/YYYY',
    "Gestion électronique de l'accusé de réception": 'Electronic management of the acknowledgment of receipt',
    'Gestion des plis non distribués': 'Management of undelivered letters',
    'Courriers à envoyer': 'Letters to be sent',
    Ajouter: 'Add',
    Supprimer: 'Delete',
    'Analyser et prévisualiser {n} fichier': 'Analyze and preview {n} file',
    'Analyser et prévisualiser {n} fichiers': 'Analyze and preview {n} files',
    '{n} fichier à traiter et analyser': '{n} file to analyze',
    '{n} fichiers à traiter et analyser': '{n} files to analyze',
    '{n} fichiers': '{n} files',
    'Fichier {n} en cours de traitement...': 'Handling file {n}…',
    browser_can_freeze_title: 'Grouping files by recipients…',
    browser_can_freeze: 'Your browser can freeze during the grouping operations. Please wait…',
    Destinataires: 'Recipients',
    'Erreurs détectées': 'Errors',
    'Re-vérifier': 'Check again',
    'Aucun courrier détecté.': 'No letter detected.',
    "Etes-vous sur d'avoir choisir la bonne règle ({name}) ?": 'Are you sure you have chosen the right rule ({name})?',
    "Erreur lors du lancement de l'envoi groupé.": 'Error when launching the batch.',
    'Vous devez': 'You have to',
    'ajouter un moyen de paiement': 'add a payment method',
    'pour pouvoir lancer votre envoi groupé.': 'to launch your batch.',
    'Modifier les fichiers': 'Edit files',
    'Re-vérifier les courriers': 'Check address errors',
    'Envoi des fichiers en cours...': 'Sending files…',
    'Envoyer {n} courrier': 'Send {n} letter',
    'Envoyer {n} courriers': 'Send {n} letters',
    "Confirmer l'envoi de {n} courrier": 'Confirm sending of {n} letter',
    "Confirmer l'envoi de {n} courriers": 'Confirm sending of {n} letters',
    'Voulez-vous envoyer ce courrier': 'Do you want to send this letter',
    'Voulez-vous envoyer ces {n} courriers': 'Do you want to send these {n} letters',
    'malgré une erreur': 'despite one error',
    'malgré {n} erreurs': 'despite {n} errors',
    Envoyer: 'Send',
    'Modifier la règle': 'Edit rule',
    'Modfier les fichiers': 'Edit files',
    courriers: 'letters',
    'Envoi groupé du': 'New batch at',
    "Afficher les envois groupés de toute l'équipe": 'Display batches from all members',
    'Mes envois groupés': 'My batches',
    'Tous les envois groupés': 'My batches',
    "Supprimer l'envoi groupé": 'Cancel batch',
    'Voulez-vous vraiment supprimer cet envoi groupé ?': 'Do you really want to cancel this batch ?',
    Annuler: 'Cancel',
    Crée: 'Created',
    'Mis à jour': 'Updated',
    Envoyé: 'Sent',
    'Envoi prévu dans': 'Will be sent in',
    'Envoyé à': 'Sent at',
    destinataires: 'recipients',
    'En erreur': 'Error',
    Créé: 'Created',
    'En attente': 'Waiting',
    "Vous n'avez aucun envoi groupé.": 'You don’t have any batch yet.',
    "Votre équipe n'a aucun envoi groupé.": 'Your team has no batches yet.',
    'Pour créer votre 1er envoi groupé': 'To create your first batch',
    'cliquez-ici': 'click here',
    "Nom de l'envoi groupé": 'Batch name',
    Créer: 'Submit',
    'Envoi Groupé': 'Batches',
    Résumé: 'Summary',
    'Envoi groupé en cours de préparation...': 'Batch in preparation…',
    "Création de l'envoi groupé en cours...": 'Creating the batch…',
    "Vous pouvez quitter cette page, vos courriers continueront à s'envoyer.": 'You can leave this page, your mails will continue to be sent.',
    Créée: 'Created',
    Restant: 'Remaining',
    Erreur: 'Error',
    'Envoi en impression programmé pour le': 'Printing programmed for the',
    'Date de suppression des données personnelles': 'Date of deletion of personal data',
    "Annuler l'envoi groupé": 'Cancel batch',
    "Impossible d'annuler cet envoi groupé car celui-ci a déjà été envoyé.": 'This batch can not be canceled because it has already been sent.',
    'Cet envoi a été annulé.': 'This batch has been canceled.',
    'Voulez-vous vraiment annuler cet envoi groupé ?': 'Do you really want to cancel this batch ?',
    "Oui. Annuler l'envoi groupé": 'Yes. Cancel this batch',
    Détails: 'Details',
    "Date d'envoi en impression": 'Send date',
    'Recto-Verso': 'Both sides',
    'Notification si pli non distribué': 'Management of undelivered letters',
    "Impression de l'adresse de l'expéditeur": 'Print sender address',
    "Position de l'adresse du destinataire": 'Address placement',
    Marge: 'Margin',
    'Date de création': 'Created at',
    'Date de mise à jour': 'Updated at',
    Fermer: 'Close',
    'Page précédente': 'Previous page',
    'Page suivante': 'Next page',
    Précédent: 'Previous',
    'Veuillez ajouter un CSV': 'Add a CSV file',
    "Couper l'adresse en 2 parties": 'Split address in 2 parts',
    'Vous devez ajouter au moins un destinataire.': 'You have to add at least one recipient.',
    'Vous devez choisir un fichier ou un template source.': 'You have to add a file or a template.',
    'Vous devez choisir un template dans la liste.': 'You have to select a template from the list.',
    'Vous devez ajouter un fichier à envoyer.': 'You have to add a file to send.',
    'Vous devez choisir un template pour le recto dans la liste.': 'You have to select a template for the front. ',
    'Vous devez choisir un template pour le verso dans la liste.': 'You have to select a template for the back. ',
    'Votre CSV ne contient pas les champs requis pour au moins la 1er ligne.': 'Your CSV file doesnt contain the required field on the first line.',
    "Cette campagne n'existe pas.": 'This campaign doesn’t exists.',
    Courrier: 'Letter',
    'Carte Postale': 'Postcard',
    Campagne: 'Campaign',
    'Re-vérifier les adresses': 'Re-check addresses',
    'Ajouter un CSV': 'Add a CSV file',
    'Supprimer tous les destinataires': 'Remove all recipients',
    "Sauvegarder et passer à l'étape suivante": 'Save and go to next step',
    malgré: 'despite',
    erreur: 'error',
    'Créer un nouveau modèle': 'Create a new template',
    Recto: 'Front',
    Verso: 'Back',
    'Variables par défaut': 'Default variables',
    Sauvegarder: 'Save',
    'Félicitations ! Votre campagne est prête à être envoyée.': 'Congrats ! Your campaign is ready to send.',
    Attention: 'Careful',
    destinataire: 'recipient',
    problème: 'error',
    'Modifier les destinataires': 'Edit recipients',
    'Vous devez ': 'You have to',
    'pour pouvoir lancer votre campagne.': 'to launch your campaign.',
    "Erreur lors de l'envoi de votre": 'Error when launching',
    lettre: 'letter',
    'carte postale': 'postcard',
    'Prévisualiser votre': 'Preview the',
    courrier: 'letter',
    'Lancer la campagne': 'Launch the campaign',
    'Voulez-vous vraiment lancer cette campagne ?': 'Do you really want to launch this campaign ?',
    'Nouvelle Campagne': 'New Campaign',
    'Nouvelle campagne': 'New campaign',
    "Afficher les campagnes de toute l'équipe": 'Display campaigns from all members',
    'Mes campagnes': 'My Campaigns',
    'Toutes les campagnes': 'All Campaigns',
    'Voir / Modifier': 'View / Edit',
    'Supprimer la campagne': 'Delete the campaign',
    'Voulez-vous vraiment supprimer cette campagne ?': 'Do you really want to delete this campaign ?',
    'Carte postale': 'Postcard',
    'Crée le': 'Created on',
    'Mis à jour le': 'Updated on',
    'Envoi prévu le': 'Will be sent on',
    "Vous n'avez aucune campagne.": 'You don’t have any campaigns.',
    "Votre équipe n'a aucune campagne.": 'Your team has no campaigns yet.',
    'Pour créer votre 1ère campagne': 'To create your first campaign',
    'Nom de la campagne': 'Campaign name',
    'Type de campagne': 'Campaign type',
    'Campagne en cours de préparation...': 'Campaign in preparation…',
    'Création de la campagne en cours...': 'Creating the campaign…',
    "Vous pouvez quitter cette page, votre campagne continuera à s'envoyer.": 'You can leave this page, your mails will continue to be sent.',
    'Annuler la campagne': 'Cancel the campaign',
    "Impossible d'annuler cette campagne car celle-ci a déjà été envoyée.": 'This campaign can not be canceled because it has already been sent.',
    'Cette campagne a été annulé.': 'This campaign has been canceled.',
    'Voulez-vous vraiment annuler cette campagne ?': 'Do you really want to cancel this campaign ?',
    'Oui. Annuler la campagne': 'Yes. Cancel the campaign',
    "Afficher les courriers envoyés par toute l'équipe et depuis l'API": 'Display batches from all members and from the API',
    'Tableau de bord': 'Dashboard',
    'Créer une campagne': 'New campaign',
    'Envoi groupé': 'Batch',
    'Consommation Courrier': 'Global usage',
    "(Basé sur la date d'envoi)": '(Based on send date)',
    dépensé: 'spent',
    'lettre "test" envoyée': 'sent « test »  letter',
    'lettres "test" envoyées': 'sent « test »  letters',
    'lettre "live" envoyée': 'sent « live »  letter',
    'lettres "live" envoyées': 'sent « live »  letters',
    'Les lettres annulées ne sont pas comptabilisées.': 'Cancel letters doesn’t count.',
    "Les tarifs dégressifs ne sont pas appliqués car ils ne peuvent être calculés qu'à la fin du mois.":
      'Degressive rates are not applied because they can not be calculated until the end of the month.',
    "Nom de l'entreprise": 'Company name',
    'en savoir plus': 'read more',
    'Temps avant envoi': 'Cancellation window',
    '(en minutes)': '(time in minutes)',
    'Vos paramètres ont été correctement mis à jour.': 'Settings have been updated.',
    "Le rôle de l'utilisateur a été correctement mis à jour.": 'User role has been updated.',
    Intégrations: '',
    Sellsy: '',
    Identifiants: 'API ID',
    'Consumer Token': '',
    'Consumer Secret': '',
    'User Token': '',
    'User Secret': '',
    "Vous ne pouvez pas modifier vos identifiants de connexion Sellsy car l'intégration a déjà été initialisée. Contactez-nous pour modifier vos identifiants.":
      '',
    "L'initialisation s'est déroulée correctement.": '',
    "Lancer l'initialisation de l'intégration": '',
    'Vos règles ont été correctement mises à jour.': 'Settings have been updated.',
    'Sauvegarder les règles': 'Save',
    'Clés API': 'API Keys',
    'Paramètres de notification par e-mail': 'E-mail notifications',
    "Ceci ne concerne que les éléments envoyés depuis l'API": 'Only for elements sent from the API',
    "Lors d'une erreur sur un courrier": 'When a letter is in error',
    "Lors d'un retour NPAI (N'habite pas à l'adresse indiquée)":
      'When a letter can’t be distributed because the recipient doesn’t live at the specified address',
    Webhook: 'Webhook',
    'Url de webhook': 'Webhook URL',
    'Désactiver les webhooks pour les éléments envoyés depuis le dashboard MySendingBox. (Courriers, cartes postales, envois groupés et campagnes)':
      'Disable webhooks for elements sent from the MySendingBox dashboard. (Letters, postcards, batches and campaigns)',
    "Coordonnées de la zone d'adresse": 'Coordinates of the address area',
    'pas de description': 'no description',
    'Identifiant API': 'API ID',
    Copier: 'Copy',
    Description: 'Description',
    Fichier: 'File',
    'correctement ajouté': 'added',
    'Déposez un fichier Word ou PowerPoint ici ou cliquez pour rechercher dans vos fichiers.':
      'Drag and drop and Word or PowerPoint file or click to search in your computer.',
    'Mettre en forme': 'Beautify',
    Prévisualiser: 'Preview',
    'Nouveau modèle': 'New template',
    'Supprimer le template': 'Delete the template',
    'Voulez-vous vraiment supprimer ce template ?': 'Do you really want to delete this template ?',
    'Pas de description': 'No description',
    "Vous n'avez aucun template.": 'You don’t have any templates.',
    'Pour créer votre 1er template': 'To create your first template',
    'Nom du template': 'Template name',
    'Utilisable pour': 'Usable for',
    'Type de template': 'Template type',
    'pas de nom': 'no name',
    "Télécharger l'original": 'Download original file',
    'Faites-vous rappeler': 'We call you back',
    'Chargement en cours...': 'Loading…',
    'Tout se fait depuis votre navigateur web.': 'Everything is done from your web browser.',
    "Vous n'avez rien à installer !": 'Nothing to install !',
    'Intégrez notre API simple et moderne': 'Discover our simple and modern API',
    'Ils nous font confiance': 'Trusted by great companies',
    'Des questions ? Contactez-nous.': 'Questions ? Contact-us.',
    Connexion: 'Log in',
    'E-mail': 'E-mail',
    'Mot de passe': 'Password',
    "Vous n'avez pas encore de compte ?": 'No account yet ?',
    'Inscrivez-vous !': 'Sign-in !',
    'Mot de passe oublié ?': 'Forgot password ?',
    Inscription: 'Signup',
    'Vous avez déjà un compte ?': 'You already have an account ?',
    'Connectez-vous !': 'Sign-in !',
    'Bonjour, je souhaiterai en savoir plus sur vos offres.': 'Hi, i would like to know more about your offer.',
    'Des questions ?': 'Questions ?',
    "Je n'ai pas encore de moyen de paiement": 'I don’t have any payment method for now',
    "En cours d'édition": 'In edition',
    "En cours d'envoi": 'Being sent',
    Terminé: 'Finished',
    Lancé: 'Launched',
    Annulé: 'Cancelled',
    Administrateur: 'Admin',
    Editeur: 'Editor',
    'En attente de paiement': 'Waiting for payment',
    Payée: 'Paid',
    password_too_short: 'The password is too short. Must be at least 8 chars.',
    user_exists: 'This e-mail address is already used.',
    deactivated: 'Account disabled. Contact-us.',
    password_not_set: 'The password for this account has not been set yet. Ask for a reset password e-mail.',
    access_denied: 'Wrong e-mail or password.',
    missing_email: 'Missing E-mail.',
    Verte: 'Verte',
    Prioritaire: 'Prioritaire',
    Recommandé: 'Recommandé',
    Ecopli: 'Ecopli',
    'Recommandé avec AR': 'Recommandé avec AR',
    'Publipostage / Campagne': 'Direct mail / Campaign',
  },
  options: {
    plural_rule: 'n > 1',
    plural_number: '2',
  },
  fr: {
    'Vous devez ajouter un moyen de paiement pour pouvoir envoyer une carte postale':
      'Vous devez ajouter un moyen de paiement pour pouvoir envoyer une carte postale.',
    'Vous devez ajouter un moyen de paiement pour faire un envoi.': 'Vous devez ajouter un moyen de paiement pour faire un envoi.',
    'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre campagne.':
      'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre campagne.',
    'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre envoi groupé.':
      'Vous devez ajouter un moyen de paiement pour pouvoir lancer votre envoi groupé.',
    'Vous devez  ajouter un moyen de paiement pour pouvoir lancer votre campagne.':
      'Vous devez  ajouter un moyen de paiement pour pouvoir lancer votre campagne.',
    missing_payment_information: 'Vous devez ajouter un moyen de paiement',
    'Votre numéro de téléphone est nécessaire.': 'Votre numéro de téléphone est nécessaire.',
    "Erreur lors de l'envoi de votre demande.": "Erreur lors de l'envoi de votre demande.",
    'Veuillez réessayer.': 'Veuillez réessayer.',
    'On vous rappelle': 'On vous rappelle',
    Nom: 'Nom',
    Société: 'Société',
    Téléphone: 'Téléphone',
    'Bien reçu, on vous rappelle très vite.': 'Bien reçu, on vous rappelle très vite.',
    Valider: 'Valider',
    Ou: 'Ou',
    'Appelez-nous au': 'Appelez-nous au',
    insufficient_right: 'Vous n’avez pas les droits d’accès nécessaires.',
    Actualiser: 'Actualiser',
    Coût: 'Coût',
    estimation: 'estimation',
    Affranchissement: 'Affranchissement',
    Service: 'Service',
    "Besoin d'aide ?": "Besoin d'aide ?",
    'Lancer la facturation': 'Lancer la facturation',
    'Voulez-vous vraiment lancer la facturation ?': 'Voulez-vous vraiment lancer la facturation ?',
    Non: 'Non',
    'Oui. Lancer la facturation.': 'Oui. Lancer la facturation.',
    Général: 'Général',
    "Vue d'ensemble": "Vue d'ensemble",
    Statistiques: 'Statistiques',
    Invoicing: 'Invoicing',
    'Simulateur de prix': 'Simulateur de prix',
    Services: 'Services',
    Lettres: 'Lettres',
    Historique: 'Historique',
    'Envoyer une nouvelle lettre': 'Envoyer une nouvelle lettre',
    'Rechercher un courrier': 'Rechercher un courrier',
    'Cartes Postales': 'Cartes Postales',
    'Envoyer une nouvelle carte postale': 'Envoyer une nouvelle carte postale',
    Campagnes: 'Campagnes',
    'Envois groupés': 'Envois groupés',
    Outils: 'Outils',
    Templates: 'Templates',
    'Règles de transformation': 'Règles de transformation',
    'Débogueur de webhook': 'Débogueur de webhook',
    Paramètres: 'Paramètres',
    Compte: 'Compte',
    Equipe: 'Equipe',
    Profil: 'Profil',
    Facturation: 'Facturation',
    API: 'API',
    'Clés et Webhook': 'Clés et Webhook',
    'Données personnelles': 'Données personnelles',
    'Nom du fichier': 'Nom du fichier',
    Voir: 'Voir',
    'Page {start} à {end}': 'Page {start} à {end}',
    'Ligne 1': 'Ligne 1',
    'Ligne 2': 'Ligne 2',
    'Ligne 3': 'Ligne 3',
    'Code Postal': 'Code Postal',
    Ville: 'Ville',
    Pays: 'Pays',
    Précedent: 'Précedent',
    Suivant: 'Suivant',
    'Veuillez glissez-déposez des fichiers PDF': 'Veuillez glissez-déposez des fichiers PDF',
    'Chargement...': 'Chargement...',
    sur: 'sur',
    fichiers: 'fichiers',
    'Vous devez sélectionner une règle de transformation.': 'Vous devez sélectionner une règle de transformation.',
    'Vous devez sélectionner un pays par défaut.': 'Vous devez sélectionner un pays par défaut.',
    "Vous devez indiquer le nom ou la société de l'expéditeur.": "Vous devez indiquer le nom ou la société de l'expéditeur.",
    "Vous devez indiquer l'adresse de l'expéditeur.": "Vous devez indiquer l'adresse de l'expéditeur.",
    "Vous devez indiquer le code postal de l'expéditeur.": "Vous devez indiquer le code postal de l'expéditeur.",
    "Vous devez indiquer la ville de l'expéditeur.": "Vous devez indiquer la ville de l'expéditeur.",
    "Vous devez indiquer le pays de l'expéditeur.": "Vous devez indiquer le pays de l'expéditeur.",
    "Les mots clés de recherche n'ont pas été détectés sur la 1er page du document.":
      "Les mots clés de recherche n'ont pas été détectés sur la 1er page du document.",
    'Les fichiers ajoutés correspondent-ils à la règle utilisée ?': 'Les fichiers ajoutés correspondent-ils à la règle utilisée ?',
    "Les mots clés de recherche n'ont pas été détectés sur la dernière page du document.":
      "Les mots clés de recherche n'ont pas été détectés sur la dernière page du document.",
    'La structure du fichier est invalide.': 'La structure du fichier est invalide.',
    "Est-ce bien un fichier PDF ? N'est-il pas corrompu ?": "Est-ce bien un fichier PDF ? N'est-il pas corrompu ?",
    'Erreur lors de la prévisualisation avant envoi.': 'Erreur lors de la prévisualisation avant envoi.',
    'La règle est-elle correctement paramétrée ?': 'La règle est-elle correctement paramétrée ?',
    "Cet envoi groupé n'existe pas": "Cet envoi groupé n'existe pas",
    'Nouvel envoi groupé': 'Nouvel envoi groupé',
    'Règle de transformation': 'Règle de transformation',
    Modifier: 'Modifier',
    'Choisissez une règle de transformation à appliquer': 'Choisissez une règle de transformation à appliquer',
    Règle: 'Règle',
    'Créer une nouvelle règle de transformation': 'Créer une nouvelle règle de transformation',
    'Nouvelle règle': 'Nouvelle règle',
    'Choisissez une 2 ème règle de transformation à appliquer': 'Choisissez une 2 ème règle de transformation à appliquer',
    'Regrouper les documents qui ont le même destinataire dans un seul courrier': 'Regrouper les documents qui ont le même destinataire dans un seul courrier',
    Oui: 'Oui',
    "Options d'envoi": "Options d'envoi",
    'Pays du destinataire si non lu sur le PDF': 'Pays du destinataire si non lu sur le PDF',
    Expéditeur: 'Expéditeur',
    "Ligne d'adresse 1": "Ligne d'adresse 1",
    "Ligne d'adresse 2": "Ligne d'adresse 2",
    "Les paramètres d'envoi par défaut de la règle {n} ont été appliqués.": "Les paramètres d'envoi par défaut de la règle {n} ont été appliqués.",
    Options: 'Options',
    Couleur: 'Couleur',
    'Recto / verso': 'Recto / verso',
    "Position de l'addresse": "Position de l'addresse",
    'Première page du document': 'Première page du document',
    'Ajouter une page blanche': 'Ajouter une page blanche',
    'Vitesse de remise en poste': 'Vitesse de remise en poste',
    "Plus d'options": "Plus d'options",
    "Fenêtre d'enveloppe": "Fenêtre d'enveloppe",
    Simple: 'Simple',
    Double: 'Double',
    "Type d'enveloppe": "Type d'enveloppe",
    'C6 (Petite)': 'C6 (Petite)',
    'C4 (Grande)': 'C4 (Grande)',
    'Nombre correspondant à des pixels': 'Nombre correspondant à des pixels',
    'Marge à appliquer autour du document': 'Marge à appliquer autour du document',
    'Ajouter une pièce jointe à chaque courrier': 'Ajouter une pièce jointe à chaque courrier',
    'Position de la pièce jointe': 'Position de la pièce jointe',
    'Dernière page du document': 'Dernière page du document',
    "Date d'envoi en impression - DD/MM/YYYY": "Date d'envoi en impression - DD/MM/YYYY",
    "Gestion électronique de l'accusé de réception": "Gestion électronique de l'accusé de réception",
    'Gestion des plis non distribués': 'Gestion des plis non distribués',
    'Courriers à envoyer': 'Courriers à envoyer',
    Ajouter: 'Ajouter',
    Supprimer: 'Supprimer',
    'Analyser et prévisualiser {n} fichier': 'Analyser et prévisualiser {n} fichier',
    'Analyser et prévisualiser {n} fichiers': 'Analyser et prévisualiser {n} fichiers',
    '{n} fichier à traiter et analyser': '{n} fichier à traiter et analyser',
    '{n} fichiers à traiter et analyser': '{n} fichiers à traiter et analyser',
    '{n} fichiers': '{n} fichiers',
    'Fichier {n} en cours de traitement...': 'Fichier {n} en cours de traitement...',
    browser_can_freeze_title: 'Regroupement des fichiers en cours…',
    browser_can_freeze: 'Votre navigateur peut se figer lors du regroupement des fichiers. Il suffit de patienter quelques secondes.',
    Destinataires: 'Destinataires',
    'Erreurs détectées': 'Erreurs détectées',
    'Re-vérifier': 'Re-vérifier',
    'Aucun courrier détecté.': 'Aucun courrier détecté.',
    "Etes-vous sur d'avoir choisir la bonne règle ({name}) ?": "Etes-vous sur d'avoir choisir la bonne règle ({name}) ?",
    "Erreur lors du lancement de l'envoi groupé.": "Erreur lors du lancement de l'envoi groupé.",
    'Vous devez': 'Vous devez',
    'ajouter un moyen de paiement': 'ajouter un moyen de paiement',
    'pour pouvoir lancer votre envoi groupé.': 'pour pouvoir lancer votre envoi groupé.',
    'Modifier les fichiers': 'Modifier les fichiers',
    'Re-vérifier les courriers': 'Re-vérifier les courriers',
    'Envoi des fichiers en cours...': 'Envoi des fichiers en cours...',
    'Envoyer {n} courrier': 'Envoyer {n} courrier',
    'Envoyer {n} courriers': 'Envoyer {n} courriers',
    "Confirmer l'envoi de {n} courrier": "Confirmer l'envoi de {n} courrier",
    "Confirmer l'envoi de {n} courriers": "Confirmer l'envoi de {n} courriers",
    'Voulez-vous envoyer ce courrier': 'Voulez-vous envoyer ce courrier',
    'Voulez-vous envoyer ces {n} courriers': 'Voulez-vous envoyer ces {n} courriers',
    'malgré une erreur': 'malgré une erreur',
    'malgré {n} erreurs': 'malgré {n} erreurs',
    Envoyer: 'Envoyer',
    'Modifier la règle': 'Modifier la règle',
    'Modfier les fichiers': 'Modfier les fichiers',
    courriers: 'courriers',
    'Envoi groupé du': 'Envoi groupé du',
    "Afficher les envois groupés de toute l'équipe": "Afficher les envois groupés de toute l'équipe",
    'Mes envois groupés': 'Mes envois groupés',
    'Tous les envois groupés': 'Tous les envois groupés',
    "Supprimer l'envoi groupé": "Supprimer l'envoi groupé",
    'Voulez-vous vraiment supprimer cet envoi groupé ?': 'Voulez-vous vraiment supprimer cet envoi groupé ?',
    Annuler: 'Annuler',
    Crée: 'Crée',
    'Mis à jour': 'Mis à jour',
    Envoyé: 'Envoyé',
    'Envoi prévu dans': 'Envoi prévu dans',
    'Envoyé à': 'Envoyé à',
    destinataires: 'destinataires',
    'En erreur': 'En erreur',
    Créé: 'Créé',
    'En attente': 'En attente',
    "Vous n'avez aucun envoi groupé.": "Vous n'avez aucun envoi groupé.",
    "Votre équipe n'a aucun envoi groupé.": "Votre équipe n'a aucun envoi groupé.",
    'Pour créer votre 1er envoi groupé': 'Pour créer votre 1er envoi groupé',
    'cliquez-ici': 'cliquez-ici',
    "Nom de l'envoi groupé": "Nom de l'envoi groupé",
    Créer: 'Créer',
    'Envoi Groupé': 'Envoi Groupé',
    Résumé: 'Résumé',
    'Envoi groupé en cours de préparation...': 'Envoi groupé en cours de préparation...',
    "Création de l'envoi groupé en cours...": "Création de l'envoi groupé en cours...",
    "Vous pouvez quitter cette page, vos courriers continueront à s'envoyer.": "Vous pouvez quitter cette page, vos courriers continueront à s'envoyer.",
    Créée: 'Créée',
    Restant: 'Restant',
    Erreur: 'Erreur',
    'Envoi en impression programmé pour le': 'Envoi en impression programmé pour le',
    'Date de suppression des données personnelles': 'Date de suppression des données personnelles',
    "Annuler l'envoi groupé": "Annuler l'envoi groupé",
    "Impossible d'annuler cet envoi groupé car celui-ci a déjà été envoyé.": "Impossible d'annuler cet envoi groupé car celui-ci a déjà été envoyé.",
    'Cet envoi a été annulé.': 'Cet envoi a été annulé.',
    'Voulez-vous vraiment annuler cet envoi groupé ?': 'Voulez-vous vraiment annuler cet envoi groupé ?',
    "Oui. Annuler l'envoi groupé": "Oui. Annuler l'envoi groupé",
    Détails: 'Détails',
    "Date d'envoi en impression": "Date d'envoi en impression",
    'Recto-Verso': 'Recto-Verso',
    'Notification si pli non distribué': 'Notification si pli non distribué',
    "Impression de l'adresse de l'expéditeur": "Impression de l'adresse de l'expéditeur",
    "Position de l'adresse du destinataire": "Position de l'adresse du destinataire",
    Marge: 'Marge',
    'Date de création': 'Date de création',
    'Date de mise à jour': 'Date de mise à jour',
    Fermer: 'Fermer',
    'Page précédente': 'Page précédente',
    'Page suivante': 'Page suivante',
    Précédent: 'Précédent',
    'Veuillez ajouter un CSV': 'Veuillez ajouter un CSV',
    "Couper l'adresse en 2 parties": "Couper l'adresse en 2 parties",
    'Vous devez ajouter au moins un destinataire.': 'Vous devez ajouter au moins un destinataire.',
    'Vous devez choisir un fichier ou un template source.': 'Vous devez choisir un fichier ou un template source.',
    'Vous devez choisir un template dans la liste.': 'Vous devez choisir un template dans la liste.',
    'Vous devez ajouter un fichier à envoyer.': 'Vous devez ajouter un fichier à envoyer.',
    'Vous devez choisir un template pour le recto dans la liste.': 'Vous devez choisir un template pour le recto dans la liste.',
    'Vous devez choisir un template pour le verso dans la liste.': 'Vous devez choisir un template pour le verso dans la liste.',
    'Votre CSV ne contient pas les champs requis pour au moins la 1er ligne.': 'Votre CSV ne contient pas les champs requis pour au moins la 1er ligne.',
    "Cette campagne n'existe pas.": "Cette campagne n'existe pas.",
    Courrier: 'Courrier',
    'Carte Postale': 'Carte Postale',
    Campagne: 'Campagne',
    'Re-vérifier les adresses': 'Re-vérifier les adresses',
    'Ajouter un CSV': 'Ajouter un CSV',
    'Supprimer tous les destinataires': 'Supprimer tous les destinataires',
    "Sauvegarder et passer à l'étape suivante": "Sauvegarder et passer à l'étape suivante",
    malgré: 'malgré',
    erreur: 'erreur',
    'Créer un nouveau modèle': 'Créer un nouveau template',
    Recto: 'Recto',
    Verso: 'Verso',
    'Variables par défaut': 'Variables par défaut',
    Sauvegarder: 'Sauvegarder',
    'Félicitations ! Votre campagne est prête à être envoyée.': 'Félicitations ! Votre campagne est prête à être envoyée.',
    Attention: 'Attention',
    destinataire: 'destinataire',
    problème: 'problème',
    'Modifier les destinataires': 'Modifier les destinataires',
    'Vous devez ': 'Vous devez ',
    'pour pouvoir lancer votre campagne.': 'pour pouvoir lancer votre campagne.',
    "Erreur lors de l'envoi de votre": "Erreur lors de l'envoi de votre",
    lettre: 'lettre',
    'carte postale': 'carte postale',
    'Prévisualiser votre': 'Prévisualiser votre',
    courrier: 'courrier',
    'Lancer la campagne': 'Lancer la campagne',
    'Voulez-vous vraiment lancer cette campagne ?': 'Voulez-vous vraiment lancer cette campagne ?',
    'Nouvelle Campagne': 'Nouvelle Campagne',
    'Nouvelle campagne': 'Nouvelle campagne',
    "Afficher les campagnes de toute l'équipe": "Afficher les campagnes de toute l'équipe",
    'Mes campagnes': 'Mes campagnes',
    'Toutes les campagnes': 'Toutes les campagnes',
    'Voir / Modifier': 'Voir / Modifier',
    'Supprimer la campagne': 'Supprimer la campagne',
    'Voulez-vous vraiment supprimer cette campagne ?': 'Voulez-vous vraiment supprimer cette campagne ?',
    'Carte postale': 'Carte postale',
    'Crée le': 'Crée le',
    'Mis à jour le': 'Mis à jour le',
    'Envoi prévu le': 'Envoi prévu le',
    "Vous n'avez aucune campagne.": "Vous n'avez aucune campagne.",
    "Votre équipe n'a aucune campagne.": "Votre équipe n'a aucune campagne.",
    'Pour créer votre 1ère campagne': 'Pour créer votre 1ère campagne',
    'Nom de la campagne': 'Nom de la campagne',
    'Type de campagne': 'Type de campagne',
    'Campagne en cours de préparation...': 'Campagne en cours de préparation...',
    'Création de la campagne en cours...': 'Création de la campagne en cours...',
    "Vous pouvez quitter cette page, votre campagne continuera à s'envoyer.": "Vous pouvez quitter cette page, votre campagne continuera à s'envoyer.",
    'Annuler la campagne': 'Annuler la campagne',
    "Impossible d'annuler cette campagne car celle-ci a déjà été envoyée.": "Impossible d'annuler cette campagne car celle-ci a déjà été envoyée.",
    'Cette campagne a été annulé.': 'Cette campagne a été annulé.',
    'Voulez-vous vraiment annuler cette campagne ?': 'Voulez-vous vraiment annuler cette campagne ?',
    'Oui. Annuler la campagne': 'Oui. Annuler la campagne',
    "Afficher les courriers envoyés par toute l'équipe et depuis l'API": "Afficher les courriers envoyés par toute l'équipe et depuis l'API",
    'Tableau de bord': 'Tableau de bord',
    'Créer une campagne': 'Créer une campagne',
    'Envoi groupé': 'Envoi groupé',
    'Consommation Courrier': 'Consommation Courrier',
    "(Basé sur la date d'envoi)": "(Basé sur la date d'envoi)",
    dépensé: 'dépensé',
    'lettre "test" envoyée': 'lettre "test" envoyée',
    'lettres "test" envoyées': 'lettres "test" envoyées',
    'lettre "live" envoyée': 'lettre "live" envoyée',
    'lettres "live" envoyées': 'lettres "live" envoyées',
    'Les lettres annulées ne sont pas comptabilisées.': 'Les lettres annulées ne sont pas comptabilisées.',
    "Les tarifs dégressifs ne sont pas appliqués car ils ne peuvent être calculés qu'à la fin du mois.":
      "Les tarifs dégressifs ne sont pas appliqués car ils ne peuvent être calculés qu'à la fin du mois.",
    "Nom de l'entreprise": "Nom de l'entreprise",
    'en savoir plus': 'en savoir plus',
    'Temps avant envoi': 'Temps avant envoi',
    '(en minutes)': '(en minutes)',
    'Vos paramètres ont été correctement mis à jour.': 'Vos paramètres ont été correctement mis à jour.',
    "Le rôle de l'utilisateur a été correctement mis à jour.": "Le rôle de l'utilisateur a été correctement mis à jour.",
    Intégrations: '',
    Sellsy: '',
    Identifiants: 'Identifiant API',
    'Consumer Token': '',
    'Consumer Secret': '',
    'User Token': '',
    'User Secret': '',
    "Vous ne pouvez pas modifier vos identifiants de connexion Sellsy car l'intégration a déjà été initialisée. Contactez-nous pour modifier vos identifiants.":
      '',
    "L'initialisation s'est déroulée correctement.": '',
    "Lancer l'initialisation de l'intégration": '',
    'Vos règles ont été correctement mises à jour.': 'Vos paramètres ont été correctement mis à jour.',
    'Sauvegarder les règles': 'Sauvegarder',
    'Clés API': 'Clés API',
    'Paramètres de notification par e-mail': 'Paramètres de notification par e-mail',
    "Ceci ne concerne que les éléments envoyés depuis l'API": "Ceci ne concerne que les éléments envoyés depuis l'API",
    "Lors d'une erreur sur un courrier": "Lors d'une erreur sur un courrier",
    "Lors d'un retour NPAI (N'habite pas à l'adresse indiquée)": "Lors d'un retour NPAI (N'habite pas à l'adresse indiquée)",
    Webhook: 'Webhook',
    'Url de webhook': 'Url de webhook',
    'Désactiver les webhooks pour les éléments envoyés depuis le dashboard MySendingBox. (Courriers, cartes postales, envois groupés et campagnes)':
      'Désactiver les webhooks pour les éléments envoyés depuis le dashboard MySendingBox. (Courriers, cartes postales, envois groupés et campagnes)',
    "Coordonnées de la zone d'adresse": "Coordonnées de la zone d'adresse",
    'pas de description': 'pas de description',
    'Identifiant API': 'Identifiant API',
    Copier: 'Copier',
    Description: 'Description',
    Fichier: 'Fichier',
    'correctement ajouté': 'correctement ajouté',
    'Déposez un fichier Word ou PowerPoint ici ou cliquez pour rechercher dans vos fichiers.':
      'Déposez un fichier Word ou PowerPoint ici ou cliquez pour rechercher dans vos fichiers.',
    'Mettre en forme': 'Mettre en forme',
    Prévisualiser: 'Prévisualiser',
    'Nouveau modèle': 'Nouveau modèle',
    'Supprimer le template': 'Supprimer le template',
    'Voulez-vous vraiment supprimer ce template ?': 'Voulez-vous vraiment supprimer ce template ?',
    'Pas de description': 'Pas de description',
    "Vous n'avez aucun template.": "Vous n'avez aucun template.",
    'Pour créer votre 1er template': 'Pour créer votre 1er template',
    'Nom du template': 'Nom du template',
    'Utilisable pour': 'Utilisable pour',
    'Type de template': 'Type de template',
    'pas de nom': 'pas de nom',
    "Télécharger l'original": "Télécharger l'original",
    'Faites-vous rappeler': 'Faites-vous rappeler',
    'Chargement en cours...': 'Chargement en cours...',
    'Tout se fait depuis votre navigateur web.': 'Tout se fait depuis votre navigateur web.',
    "Vous n'avez rien à installer !": "Vous n'avez rien à installer !",
    'Intégrez notre API simple et moderne': 'Intégrez notre API simple et moderne',
    'Ils nous font confiance': 'Ils nous font confiance',
    'Des questions ? Contactez-nous.': 'Des questions ? Contactez-nous.',
    Connexion: 'Connexion',
    'E-mail': 'E-mail',
    'Mot de passe': 'Mot de passe',
    "Vous n'avez pas encore de compte ?": "Vous n'avez pas encore de compte ?",
    'Inscrivez-vous !': 'Inscrivez-vous !',
    'Mot de passe oublié ?': 'Mot de passe oublié ?',
    Inscription: 'Inscription',
    'Vous avez déjà un compte ?': 'Vous avez déjà un compte ?',
    'Connectez-vous !': 'Connectez-vous !',
    'Bonjour, je souhaiterai en savoir plus sur vos offres.': 'Bonjour, je souhaiterai en savoir plus sur vos offres.',
    'Des questions ?': 'Des questions ?',
    "Je n'ai pas encore de moyen de paiement": "Je n'ai pas encore de moyen de paiement",
    "En cours d'édition": "En cours d'édition",
    "En cours d'envoi": "En cours d'envoi",
    Terminé: 'Terminé',
    Lancé: 'Lancé',
    Annulé: 'Annulé',
    Administrateur: 'Administrateur',
    Editeur: 'Editeur',
    'En attente de paiement': 'En attente de paiement',
    Payée: 'Payée',
    password_too_short: 'Le mot de passe est trop court. Il doit faire au moins 8 caractères.',
    user_exists: 'Un compte existe déjà avec cet e-mail.',
    deactivated: 'Compte désactivé. Contactez-nous.',
    password_not_set: 'Le mot de passe pour ce compte n’est pas encore défini. Demandez une réinitialisation du mot de passe.',
    access_denied: 'E-mail ou mot de passe incorrect.',
    missing_email: 'E-mail manquant.',
    Verte: 'Verte',
    Prioritaire: 'Prioritaire',
    Recommandé: 'Recommandé',
    Ecopli: 'Ecopli',
    'Recommandé avec AR': 'Recommandé avec AR',
    'Publipostage / Campagne': 'Publipostage / Campagne',
  },
}
