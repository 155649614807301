import Raven from 'raven-js'

export function logException(ex: unknown, context?: any): void {
  try {
    Raven.captureException(ex, {
      extra: context,
    })
  } catch (error) {
    return error
  }
}
