import { setLanguage } from 'redux-i18n'
import LocalStorage from '../../utils/LocalStorageUtils'
import { AnyAction, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN'
export const TOGGLE_DIMMER = 'TOGGLE_DIMMER'
export const TOGGLE_PUSHER = 'TOGGLE_PUSHER'

export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW'
export const TOGGLE_USER_UPDATED = 'TOGGLE_USER_UPDATED'

export function updateLanguage(lang: string): ThunkAction<string, unknown, unknown, Action<string>> {
  return (dispatch: any): string => {
    if (!['fr', 'en'].includes(lang)) {
      lang = 'en'
    }
    dispatch(setLanguage(lang))
    LocalStorage.set('lang', lang)
    return lang
  }
}

export function toggleDimmer(visible: boolean = false): AnyAction {
  return {
    type: TOGGLE_DIMMER,
    visible,
  }
}

export function togglePusher(visible: boolean = false, text: string = ''): AnyAction {
  return {
    type: TOGGLE_PUSHER,
    visible,
    text,
  }
}

export function toggleFullscreen(visible: boolean = false): AnyAction {
  return {
    type: TOGGLE_FULLSCREEN,
    visible,
  }
}

export function togglePreview(visible: boolean = false): AnyAction {
  return {
    type: TOGGLE_PREVIEW,
    visible,
  }
}

export function toggleUserUpdated(updated: boolean = false): AnyAction {
  return {
    type: TOGGLE_USER_UPDATED,
    updated,
  }
}
