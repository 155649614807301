import LocalStorage from './LocalStorageUtils'
import Debug from 'debug'

const debug = Debug('app:RequiredUtils')

export type Locales = 'en' | 'fr'

export function getLanguage(): Locales {
  let browserLanguage = navigator.language ?? navigator.userLanguage

  debug('detected browserLanguage :', browserLanguage)

  if (browserLanguage) {
    browserLanguage = browserLanguage.split('-')[0]
  } else {
    browserLanguage = 'fr'
  }
  if (navigator.userAgent === 'ReactSnap') {
    browserLanguage = 'fr'
  }
  debug('browserLanguage : ', browserLanguage)

  let lang: string | null
  if (LocalStorage.get('lang')) {
    lang = LocalStorage.get('lang')
  } else {
    LocalStorage.set('lang', browserLanguage)
    lang = browserLanguage
  }
  const lang2 = lang !== 'fr' ? 'en' : 'fr'
  debug('setLanguage : ', lang2)
  return lang2
}

export function saveRefereer(): void {
  if (window.document.referrer) {
    LocalStorage.set('referrer', window.document.referrer)
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve: any) => setTimeout(resolve, ms))
}
