import { AnyAction } from 'redux'
import { AUTH_OK } from '../actions/action_auth'

export interface AuthState {
  isAuthenticated: boolean
}

const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
}

export default function (state: AuthState = INITIAL_STATE, action: AnyAction): AuthState {
  switch (action.type) {
    case AUTH_OK:
      return { ...state, isAuthenticated: true }

    default:
      return { ...state, isAuthenticated: false }
  }
}
