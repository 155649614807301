import React, { Component } from 'react'
import { Label, Loader, Segment } from 'semantic-ui-react'
import { createClient } from 'contentful'
import { Helmet } from 'react-helmet'
import Debug from 'debug'
import marked from './../../utils/MarkdownRenderer'
import { formatDate } from '../../utils/dateUtils'
import { RouteChildrenProps } from 'react-router'

const debug = Debug('app:containers:guides:Item')

type GuidesItemProps = RouteChildrenProps

interface GuidesItemState {
  activeItem: unknown | null
  menuItems: unknown | null
  item: any | null
  loading?: boolean
}

class GuidesItem extends Component<GuidesItemProps, GuidesItemState> {
  constructor(props: GuidesItemProps) {
    super(props)
    this.state = {
      activeItem: null,
      menuItems: null,
      item: null,
    }

    const response =
      window.snapStore &&
      window.snapStore[
        `https://cdn.contentful.com/spaces/uu95v6ljdw3h/environments/master/entries?content_type=guide&fields.url=${(props.match?.params as any).guide_id}`
      ]

    if (response && response.items && response.items.length) {
      debug('Data set from snapStore ! :', response.items)
      this.setState({
        item: {
          ...response.items[0].fields,
          updated_at: response.items[0].sys.updatedAt,
          created_at: response.items[0].sys.createdAt,
        },
      })
    }
  }

  componentDidMount(): void {
    if (!this.state.item) {
      this.fetchPost()
    }
  }

  componentDidUpdate(prevProps: GuidesItemProps): void {
    debug('componentDidUpdate')
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        loading: true,
      })
      this.fetchPost()
      debug('componentDidUpdate IF')
    }
  }

  fetchPost = (): any => {
    let preview = false
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      preview = true
    }

    const client = createClient({
      space: 'uu95v6ljdw3h',
      host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
      accessToken: preview
        ? 'd55e91a0ae923847545ae6b4b82fb0887f47c77ccdd6d879bcbc0e5d3d2e0e7d'
        : 'eb516d89ae251de32c88b43be5444b982b6f02031e56005f18c99888eb955f25',
    })

    debug('Fetching Entries....')

    client
      .getEntries<any>({
        content_type: 'guide',
        'fields.url': (this.props.match?.params as any).guide_id,
      })
      .then((response: any) => {
        debug(response.items)
        return this.setState({
          item: {
            ...response.items[0].fields,
            updated_at: response.items[0].sys.updatedAt,
            created_at: response.items[0].sys.createdAt,
          },
          loading: false,
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  render(): JSX.Element {
    const { item, loading } = this.state

    if (!item || loading) {
      return (
        <Segment basic style={{ minHeight: '330px' }} className="center-text">
          <Loader active>Chargement en cours...</Loader>
        </Segment>
      )
    } else {
      return (
        <div>
          <Label attached="top left" color="blue">
            {item.category}
          </Label>
          <Label attached="top right">Mis à jour le {formatDate(item.updated_at, `dd MMMM yyyy à HH'h'mm`)}</Label>
          <Helmet>
            <title>{item.title} - Guide - MySendingBox - Solutions d'envoi de courrier et de carte postale</title>
            <meta name="description" content={item.description} />
            <meta property="og:title" content={`${item.title} - Guide - MySendingBox - Solutions d'envoi de courrier et de carte postale`} />

            <meta property="og:description" content={item.description} />
          </Helmet>
          <div className="m-top-30" dangerouslySetInnerHTML={{ __html: marked(item.content || '<i>Aucun contenu</i>') }} />
        </div>
      )
    }
  }
}

export default GuidesItem
