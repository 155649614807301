/** *************> errors.config.ts
 * Configuration data for each errors
 * Available parameters :
 * - errorName : String. Name of the error
 * - errorKey : String. Key for the error. Must be the path to the "errors" data of language files
 * - errorMessage : String. Detailed message of the error
 */

// *> Constant error interface
export interface ConstantErrorInterface {
  errorName: string
  errorKey: string
  errorMessage: string
}

export const OIDC_INIT_ERROR = {
  errorName: 'OIDC Auth Init Error',
  errorKey: 'auth.oidcInitError',
  errorMessage: 'An error occurred when initializing IDP authentication page',
}

export const HTTP_RESPONSE_ERROR = {
  errorName: 'HTTP Response Error',
  errorKey: 'http.responseError',
  errorMessage: 'An error occurred when calling API route',
}

export const CLIENT_API_ERROR = {
  errorName: 'Client Api Error',
  errorKey: 'api.client.error',
  errorMessage: 'An error occurred when calling Client route',
}

const axiosErrorName = 'Axios Error'

export const AXIOS_ERROR = {
  errorName: axiosErrorName,
  errorKey: 'axios.error',
  errorMessage: 'Internal axios error',
}

export const AXIOS_INTERCEPTOR_OIDC_ERROR = {
  errorName: 'Axios OIDC Interceptor Error',
  errorKey: 'axios.interceptor.oidcError',
  errorMessage: 'Failure in refreshing access token with OIDC',
}
