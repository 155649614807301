import {
  WORKFLOW_INITIALIZE,
  WORKFLOW_GOTO,
  WORKFLOW_DISABLE_LOADER,
  WORKFLOW_ENABLE_LOADER,
  WORKFLOW_DISABLE_IS_NAVIGATION_BLOCKED,
  WORKFLOW_ENABLE_IS_NAVIGATION_BLOCKED,
  WORKFLOW_ENABLE_STEP_CHANGE,
  WORKFLOW_DISABLE_STEP_CHANGE,
  WORKFLOW_ENABLE_NEXT_STEP_CHANGE,
  WORKFLOW_DISABLE_NEXT_STEP_CHANGE,
  WORKFLOW_GOTO_NEXT,
  WORKFLOW_GOTO_PREVIOUS,
} from '../actions/action_workflow'
import { AnyAction } from 'redux'
import assert from 'assert'

export interface WorkflowStateType {
  [x: string]: any
  allowChange: boolean
  goNext: boolean
  loader: boolean
  isNavigationBlocked: boolean
  steps?: any[]
  form_id?: string
  step?: string
}

const INITIAL_STATE: WorkflowStateType = {
  allowChange: true,
  goNext: true,
  loader: false,
  isNavigationBlocked: false,
}

export default function (state: WorkflowStateType = INITIAL_STATE, action: AnyAction): WorkflowStateType {
  // we need to create these ahead of time so that we do not create variables multiple time
  // within the same scope block, but we can still have she same variable name
  let new_state: WorkflowStateType, current_step: any
  switch (action.type) {
    case WORKFLOW_INITIALIZE:
      return { ...state, steps: action.steps, form_id: action.form_id, step: action.initial_step }

    case WORKFLOW_GOTO:
      assert(state.form_id)
      new_state = { ...state }
      new_state.step = action.step
      return new_state

    case WORKFLOW_GOTO_NEXT:
      assert(state.form_id)
      assert(state.steps)
      new_state = { ...state }
      current_step = new_state.step
      if (state.steps.indexOf(current_step) === state.steps.length) {
        throw Error(`Trying to go to next step when the current step is the last. current step: ${current_step}`)
      }
      const next_step = state.steps[state.steps.indexOf(current_step) + 1]
      new_state.step = next_step
      return new_state

    case WORKFLOW_GOTO_PREVIOUS:
      assert(state.form_id)
      assert(state.steps)
      new_state = { ...state }
      current_step = new_state.step
      if (state.steps.indexOf(current_step) === 0) {
        throw Error(`Trying to go to previous step when the current step is the first. current step: ${current_step}`)
      }
      const previous_step = state.steps[state.steps.indexOf(current_step) - 1]
      new_state.step = previous_step
      return new_state

    case WORKFLOW_ENABLE_STEP_CHANGE:
      return { ...state, allowChange: true }

    case WORKFLOW_DISABLE_STEP_CHANGE:
      return { ...state, allowChange: false }

    case WORKFLOW_ENABLE_NEXT_STEP_CHANGE:
      return { ...state, goNext: true }

    case WORKFLOW_DISABLE_NEXT_STEP_CHANGE:
      return { ...state, goNext: false }

    case WORKFLOW_DISABLE_LOADER:
      return { ...state, loader: false }

    case WORKFLOW_ENABLE_LOADER:
      return { ...state, loader: true }

    case WORKFLOW_DISABLE_IS_NAVIGATION_BLOCKED:
      return { ...state, isNavigationBlocked: false }

    case WORKFLOW_ENABLE_IS_NAVIGATION_BLOCKED:
      return { ...state, isNavigationBlocked: true }

    default:
      return state
  }
}
