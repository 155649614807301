import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './utils/ErrorSetup'

import React from 'react'
import { hydrate, render } from 'react-dom'

import { unregister } from './registerServiceWorker'
import './Global.scss'
import './fomantic/dist/semantic.min.css'
import Routes from './routes'
import ServiceProvider from './containers/ServiceProvider'

const target = document.querySelector('#root')

// render(<Routes/>, target)

if (target?.hasChildNodes()) {
  hydrate(
    <ServiceProvider>
      <Routes />
    </ServiceProvider>,
    target
  )
} else {
  render(
    <ServiceProvider>
      <Routes />
    </ServiceProvider>,
    target
  )
}

// HMR : https://medium.com/superhighfives/hot-reloading-create-react-app-73297a00dcad
if (module.hot && process.env.REACT_APP_NODE_ENV !== 'production') {
  module.hot.accept('./routes', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./routes').default
    render(<NextApp />, target)
  })
}

// Uncomment line below to enable PWA https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#making-a-progressive-web-app
// registerServiceWorker()

// Comment line below to enable PWA
unregister()
