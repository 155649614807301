import { Service } from './Service'
import OidcServiceInstance, { OidcService } from './oidc.service'
import { API_URL } from '../utils/Constants'
import getAxiosResponse from '../api/api'
import { AxiosResponse } from 'axios'
import { logException } from '../utils/ErrorLogger'
import get from 'lodash/get'
import { Role } from '../utils/enumRole'

export interface UserProfile {
  user_id: string
  company_id: string
  role: string
  algolia_api_key: string
  intercom_hash: string
  onBoarding: boolean
}

interface Company {
  partner: {
    can_update_account_email_from_api: boolean
    can_create_account_from_api: boolean
    without_payment_method: boolean
  }
  email_notifications: {
    error: boolean
    wrong_address: boolean
  }
  data_expiration: {
    basic: {
      type: 'day' | 'month' | 'year'
      value: number
    }
    legal: {
      type: 'year'
      value: number
    }
  }
  cancellation_window: {
    letters: number
    postcards: number
  }
  api_keys: {
    live: {
      created_at: string
      key: string
    }
    test: {
      created_at: string
      key: string
    }
  }
  stripe_info?: {
    payment_method_id: string
    setup_intent_id: string
    customer: string
    // Not number to prevent removing leading zeros
    last4: string
    // Seems to be a 2 char ISO country code
    country: string
    // Can be 'card' or 'sepa_debit' but don't know other possible values
    type: string
    // ------------------------- Only for card >>>>>>>>>>>>>>>>>>>>>>>>> //
    exp_month?: number
    exp_year?: number
    // Can be 'visa' but don't know other possible values
    brand?: string
    // Can be 'credit' but don't know other possible values
    funding?: string
    // <<<<<<<<<<<<<<<<<<<<<<<<< Only for card ------------------------- //
    // ------------------------- Only for sepa_debit >>>>>>>>>>>>>>>>>>>>>>>>> //
    mandate_reference?: string
    mandate_url?: string
    bank_code?: string
    branch_code?: string
    // <<<<<<<<<<<<<<<<<<<<<<<<< Only for sepa_debit ------------------------- //
  }
  integrations: {
    sellsy: {
      allowed: boolean
      activated: boolean
      initialized: boolean
      rules: any[]
    }
  }
  allow_express_non_lrar: boolean
  allow_sms_campaign_sending: boolean
  admin: boolean
  default_expiration_time: number
  activated: boolean
  validated_account: boolean
  ask_destineo_eligibility_is_allowed: boolean
  disable_webhook_for_dashboard_event: boolean
  postage_speed: string
  credit_card_exists: boolean
  default_pack: string
  auto_invoicing: boolean
  billing_emails: string[]
  billing_include_export: boolean
  events: any[]
  isSso?: boolean
  _id: string
  email: string
  referrer?: string
  created_at: string
  updated_at: string
  accounting_number: number
  branded_for?: string
  discount_text?: string
  discount_type?: 'percentage' | 'amount'
  discount_value?: number
  __v: number
  // Seems to be same as stripe_info.type
  payment_type?: string
  name?: string
  enableMultipleBillingAccounts?: boolean
}

export interface User {
  email_notifications: {
    error: boolean
    wrong_address: boolean
  }
  from_default?: {
    name: string
    company: string
    address_line1: string
    address_country: string
    address_postalcode: string
    address_city: string
  }
  admin: boolean
  activated: boolean
  lockUser: boolean
  onBoarding?: boolean
  _id: string
  email: string
  name?: string
  phone?: string
  role: Role
  company: Company
  created_at: string
  updated_at: string
  referrer?: string
  __v: number
}

export class UserService implements Service {
  profile: UserProfile | undefined
  user: User | undefined

  /**
   * @override init()
   * Initialize Service
   *
   * @return Promise<void>
   */
  async init(services: Record<string, Service>): Promise<void> {
    const { oidc } = services as { oidc: OidcService }

    if (oidc.isAuthenticated) {
      this.profile = await this.fetchProfile()
      this.user = await this.fetchUser()
    }
  }

  /**
   * @override run()
   * Run Service
   * Nothing to run for this service
   *
   * @return Promise<void>
   */
  async run(): Promise<void> {
    return Promise.resolve()
  }

  getProfile(): UserProfile {
    if (this.profile) {
      return this.profile
    }

    throw new Error("Can't get profile for unauthenticated user")
  }

  getUser(): User {
    if (this.user) {
      return this.user
    }

    throw new Error("Can't get user if unauthenticated")
  }

  async refreshProfile(): Promise<void> {
    const oidc = OidcServiceInstance()
    if (oidc.isAuthenticated) {
      this.profile = await this.fetchProfile()
    } else {
      throw new Error("Can't get profile for unauthenticated user")
    }
  }

  async refreshUser(): Promise<void> {
    this.user = await this.fetchUser()
  }

  /**
   * *> fetchProfile()
   * Fetch user profile information from backend
   *
   * @private
   * @return Promise<UserProfile>
   */
  private async fetchProfile(): Promise<UserProfile | undefined> {
    const oidc = OidcServiceInstance()
    return getAxiosResponse<{ profile: UserProfile }>({
      url: `${API_URL}/me`,
      method: 'get',
    })
      .then((response: AxiosResponse<{ profile: UserProfile }>) => {
        return {
          user_id: response.data.profile.user_id,
          company_id: response.data.profile.company_id,
          role: response.data.profile.role,
          algolia_api_key: response.data.profile.algolia_api_key,
          intercom_hash: response.data.profile.intercom_hash,
          onBoarding: response.data.profile.onBoarding,
        }
      })
      .catch((error: any) => {
        if (get(error, 'status') === 403) {
          oidc.loginPolicyError = get(error, 'data')
        }

        return undefined
      })
  }

  /**
   * *> fetchUser()
   * Fetch user profile information from backend
   *
   * @private
   * @return Promise<UserProfile>
   */
  private async fetchUser(): Promise<User> {
    if (this.profile) {
      return getAxiosResponse<{ user: User }>({
        url: `${API_URL}/users/${this.profile.user_id}`,
        method: 'get',
      }).then(
        (response: AxiosResponse<{ user: User }>): User => {
          return response.data.user
        },
        (error: any): any => {
          logException(error, error.response)
          throw error.response
        }
      )
    }

    throw new Error("Can't get user if unauthenticated")
  }
}

const userService = new UserService()
export default (): UserService => userService
