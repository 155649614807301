import { applyMiddleware, compose, createStore, StoreEnhancerStoreCreator } from 'redux'

import { routerMiddleware } from 'connected-react-router'

import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import createRootReducer from './redux/reducers'

export const historyStore = createBrowserHistory()

const initialState: any = {}
const enhancers: any = []
const middleware = [thunk, routerMiddleware(historyStore)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose<StoreEnhancerStoreCreator>(applyMiddleware(...middleware), ...enhancers)

const store = createStore(createRootReducer(historyStore), initialState, composedEnhancers)

export default store
