import { AxiosRequestConfig } from 'axios'

/** *************> axios.config.tsx
 * Axios configuration for each specific Interceptor
 *
 * Available parameters are in compliance with AxiosRequestConfig
 * See https://github.com/axios/axios#request-config
 */

const baseURL = process.env.REACT_APP_API_URL

// *> Default configuration
const defaultConfig: AxiosRequestConfig = {
  baseURL,
}

// *> OIDC configuration
const oidcConfig: AxiosRequestConfig = {
  baseURL,
}

export default {
  defaultConfig,
  oidcConfig,
}
