import marked from 'marked'

const renderer = new marked.Renderer()

marked.setOptions({
  renderer: renderer,
  /*  highlight: function (code) {
      return require('highlight.js').highlightAuto(code).value
    } */
})

renderer.heading = (text: any, level: any) => {
  return `<h${level} class="ui header" style=${level === 1 ? '"font-size: 1.71428571rem"' : ''}>${text}</h${level}>\n`
}
renderer.paragraph = (text: any) => {
  return `<p>${text.replace(/\n/g, '<br/>')}</p>\n`
}
renderer.table = (header: any, body: any) => {
  return '<table class="ui celled table small">\n' + '<thead>\n' + header + '</thead>\n' + '<tbody>\n' + body + '</tbody>\n' + '</table>\n'
}
renderer.listitem = (text: any) => {
  return '<li>' + text + '</li>\n'
}
renderer.image = (href: any, title: any, text: any) => {
  return `<img src='${href}' title="${text}" class='ui big centered bordered image' />`
}
renderer.link = (href: any, title: any, text: any) => {
  if ((href && href.indexOf('http') !== -1 && href.indexOf('MySendingBox.fr') === -1) || title === 'Ouvrir dans un nouvel onglet') {
    return `<a title="${title}" href="${href}" target="_blank" rel="noopener noreferrer">${text} <i aria-hidden="true" class="external small icon"></i></a>`
  } else {
    return `<a title="${title}" href="${href}">${text}</a>`
  }
}
/*
renderer.code = function (code, lang, escaped) {
  if (this.options.highlight) {
    var out = this.options.highlight(code, lang)
    if (out != null && out !== code) {
      escaped = true
      code = out
    }
  }

  if (!lang) {
    return `
<div class="ui segment">
<pre><code>${(escaped ? code : escape(code, true))}</code></pre>
</div>
`
  }

  return `
<div class="ui segment">
<a class="ui bottom right attached label">${lang}</a>
<pre>
<code class="${this.options.langPrefix} ${escape(lang, true)}">${escaped ? code : escape(code)}</code>
</pre>
</div>
`

}
*/

export default marked
