import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import IconMSBLong from './icons/IconMSBLong'
import { ServiceContext } from '../containers/ServiceProvider'

const LoginPolicyErrorPage = (): JSX.Element => {
  const { oidc: oidcService } = useContext(ServiceContext)

  const errorMessage = () => {
    if (oidcService.loginPolicyError?.login_method === 'password') {
      return `par mot de passe`
    }

    return `via ${oidcService.loginPolicyError?.login_idp}`
  }

  return (
    <>
      <Grid centered style={{ marginTop: '60px' }}>
        <Helmet>
          <title>Accès non autorisé</title>
          <meta name="description" content="Accès non autorisé" />
        </Helmet>

        <Grid.Column computer="6" mobile="16" tablet="8">
          <Segment attached textAlign="center" raised={false} color={'orange'} padded>
            <IconMSBLong />
            <Header as="h2">Accès non autorisé</Header>
            <br />
            <p style={{ marginBottom: '20px' }}>Vous ne pouvez pas accéder à ce compte {errorMessage()}</p>
            <Button onClick={() => oidcService.logout()}>
              <span className="dropdown-text">Retour à la page de connexion</span>
            </Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default LoginPolicyErrorPage
