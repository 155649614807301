import { AxiosPromise } from 'axios'
import Debug from 'debug'
import { createContext } from 'react'
import { API_URL } from './Constants'
import { logException } from './ErrorLogger'
import UserServiceInstance, { UserProfile } from '../services/user.service'
import getAxiosResponse from '../api/api'
import { Role } from './enumRole'

const debug = Debug('app:utils:AuthService')

/**
 * @deprecated use UserService
 */
export function getProfile(): UserProfile {
  const userService = UserServiceInstance()
  return userService.getProfile()
}

/**
 * @deprecated use UserService
 */
export function getUserId(): string {
  return getProfile() && getProfile().user_id
}

/**
 * @deprecated use UserService
 */
export function getCompanyId(): string {
  return getProfile() && getProfile().company_id
}

/**
 * @deprecated use UserService
 */
export function getRole(): string {
  return getProfile() && getProfile().role
}

export function isSuperAdmin(user: any): boolean {
  // debug('isSuperAdmin : ', get(auth, 'profile.super_admin', false))
  return user.admin ? user.admin : false
}

export function hasRole(role: Role): boolean {
  const userRole = getRole()
  switch (role) {
    case Role.admin:
      return userRole === Role.admin.toString()
    case Role.supervisor:
      return [Role.supervisor.toString(), Role.admin.toString()].includes(userRole)
    case Role.editor:
      return [Role.editor.toString(), Role.supervisor.toString(), Role.admin.toString()].includes(userRole)
    case Role.sender:
      return [Role.sender.toString(), Role.editor.toString(), Role.supervisor.toString(), Role.admin.toString()].includes(userRole)
    default:
      return false
  }
}

export const LoginContext = createContext({
  user: {},
  setUser: (user: any) => user,
  t: (text: any) => text,
})

export function updateUser(id: string, userData: any): any {
  debug('Updating user (%s) ...', id)
  return getAxiosResponse({
    url: `${API_URL}/users/${id}`,
    method: 'put',
    data: {
      updatedUser: userData,
    },
  }).then(
    (response: any): any => {
      return response
    },
    (error: any): any => {
      logException(error, error.response)
      throw error.response
    }
  )
}

export function updateCompany(company_id: string, data: any = {}): AxiosPromise {
  try {
    debug('updateCompany : ', company_id, ' / ', data)
    return getAxiosResponse({
      url: `${API_URL}/companies/${company_id}`,
      method: 'PUT',
      data: data,
    })
  } catch (ex) {
    throw ex
  }
}
