import React, { FC } from 'react'
import { createMedia } from '@artsy/fresnel'
export const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
})
const mediaStyles = AppMedia.createMediaStyle()
const { MediaContextProvider } = AppMedia

interface MediaHocProps {
  children: JSX.Element
}
const MediaHoc: FC<MediaHocProps> = ({ children }: MediaHocProps): JSX.Element => {
  return (
    <>
      <style>{mediaStyles}</style>
      <MediaContextProvider>{children}</MediaContextProvider>
    </>
  )
}
export default MediaHoc
