import React, { FC, useContext, useEffect, useState } from 'react'
import Intercom from 'react-intercom'
import get from 'lodash/get'
import isBot from 'isbot'
import random from 'lodash/random'
import { ServiceContext } from '../containers/ServiceProvider'

interface CustomIntercomProps {
  children: React.ReactNode
}
const CustomIntercom: FC<CustomIntercomProps> = ({ children }: CustomIntercomProps): JSX.Element => {
  const appIdProd = 'ass3pi0g'
  const appIdDev = 'tx4spr2c'
  const fake_data = random(0, 1000, true)
  const [user, setUser] = useState({} as Record<string, unknown>)
  const visible = !isBot(get(window, 'navigator.userAgent'))
  const appId = process.env.REACT_APP_NODE_ENV === 'production' ? appIdProd : appIdDev
  const { user: userService } = useContext(ServiceContext)

  useEffect(() => {
    const contextUser = userService.getUser()
    if (contextUser && contextUser._id) {
      setUser({
        user_id: contextUser._id,
        email: contextUser.email,
        fake_data,
        current_page: window.location.pathname,
        user_hash: userService.getProfile().intercom_hash,
        company: { id: contextUser.company._id, name: get(contextUser, 'company.name', 'Non défini') },
        branded_for: contextUser.company.branded_for ?? undefined,
      })
    }
  }, [])

  return (
    <>
      {visible && <Intercom appID={appId ?? appIdDev} {...user} />} <>{children}</>{' '}
    </>
  )
}

export default CustomIntercom
