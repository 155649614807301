import { DateUtils } from 'react-day-picker'

import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import isMatch from 'date-fns/isMatch'
import isBefore from 'date-fns/isBefore'
import formatDistance from 'date-fns/formatDistance'

import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import addDays from 'date-fns/addDays'

import fromUnixTime from 'date-fns/fromUnixTime'

import set from 'date-fns/set'

import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import getDateNumber from 'date-fns/getDate'

import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'

import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'

import { enUS, fr } from 'date-fns/locale'
import Debug from 'debug'
import { getLanguage } from './RequiredUtils'

const debug = Debug('app:dateUtils')

const locale = {
  fr,
  en: enUS,
}

const FORMAT_DATE_UTILS: any = 'yyyy-MM-dd'

export const getLocalDate = (): any => {
  return locale[getLanguage()]
}

export const getDate = (date: any = 'now', formatOfDate: any = FORMAT_DATE_UTILS): any => {
  if (date instanceof Date) {
    return date
  }
  if (date && typeof date === 'string' && /.*T\d{2}(:\d{2})?(:\d{2})?(.\d{3}Z)?(\+\d{2}:\d{2})?/.test(date)) {
    return parseISO(date)
  }
  if (date && typeof date === 'string' && date !== '' && date !== 'now') {
    if (!isMatch(date, formatOfDate)) {
      // throw new Error(`first params date ${date} dont match with second params format ${formatOfDate} for first params date`)
      return new Date(0)
    }
    return parse(date, formatOfDate, new Date())
  }

  if (date && date !== 'now' && !(date instanceof Date)) {
    // throw new Error("getDate accept 'string' or 'instanceof Date' for first params date")
    return new Date(0)
  }

  return new Date()
}
export const setPartOfDate = (setOption: any, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  if (!(typeof setOption === 'object')) {
    throw new Error(`first params wait 'object'
        {
            year: yyyy,
            month: [0 - 11],
            date: [1 - 31],
            hours:[0 - 23],
            minutes:[0 - 59],
            seconds:[0 - 59],
            milliseconds:[0 - 999],
        }`)
  }
  return set(getDate(date, options.formatOfDate), setOption)
}

export const getPartOfDate = (type: string, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  const newDate = getDate(date, options.formatOfDate)

  if (!['date', 'month', 'year'].includes(type)) {
    throw new Error(`first params accept 'date'or 'month' or 'year'`)
  }
  if (type === 'year') {
    return getYear(newDate)
  }
  if (type === 'month') {
    return getMonth(newDate)
  }
  if (type === 'date') {
    return getDateNumber(newDate)
  }
}

export const getStartOf = (type: string, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  const newDate = getDate(date, options.formatOfDate)
  if (!['day', 'month'].includes(type)) {
    throw new Error(`first params accept 'day' or 'month'`)
  }
  if (type === 'day') {
    return startOfDay(newDate)
  }
  if (type === 'month') {
    return startOfMonth(newDate)
  }
}

export const getEndOf = (type: string, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  const newDate = getDate(date, options.formatOfDate)
  if (!['day', 'month'].includes(type)) {
    throw new Error(`first params accept 'day' or 'month'`)
  }
  if (type === 'day') {
    return endOfDay(newDate)
  }
  if (type === 'month') {
    return endOfMonth(newDate)
  }
}

export const substractDay = (numberOfDay: number, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  return subDays(getDate(date, options.formatOfDate), numberOfDay)
}

export const substractMonth = (numberOfMonth: number, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  return subMonths(getDate(date, options.formatOfDate), numberOfMonth)
}

export const addDay = (numberOfDay: number, date: any = 'now', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  return addDays(getDate(date, options.formatOfDate), numberOfDay)
}

export const formatDate = (date: any, formatOfDate: string = 'dd/MM/yyyy', options: any = { formatOfDate: FORMAT_DATE_UTILS }): any => {
  return format(getDate(date, options.formatOfDate), formatOfDate, { locale: getLocalDate() })
}

export const distanceBetweenTwoDate = (
  date: any,
  dateStart: string = 'now',
  options: any = {
    formatOfDate: FORMAT_DATE_UTILS,
    formatDateStart: FORMAT_DATE_UTILS,
    addSuffix: false,
  }
): any => {
  return formatDistance(getDate(date, options.formatOfDate), getDate(dateStart, options.formatDateStart), {
    locale: getLocalDate(),
    addSuffix: options.addSuffix,
  })
}

export const isBeforeDate = (
  date: any,
  dateStart: string = 'now',
  options: any = {
    formatOfDate: FORMAT_DATE_UTILS,
    formatDateStart: FORMAT_DATE_UTILS,
    addSuffix: true,
  }
): any => {
  return isBefore(getDate(date, options.formatOfDate), getDate(dateStart, options.formatDateStart))
}

export const setUnixMillisecond = (timestamp: number): Date => {
  return fromUnixTime(timestamp)
}

export const formatReactDayPicker = (date: any, formatOfDate: string, localeDate: any): any => {
  debug('locale', localeDate)
  return format(getDate(date, FORMAT_DATE_UTILS), formatOfDate, { locale: getLocalDate() })
}
export const parseReactDayPicker = (str: any, formatOfDate: string, localeDate: any): any => {
  debug('locale', localeDate)
  const parsed = parse(str, formatOfDate, new Date(), { locale: getLocalDate() })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}
