import ErrorInterface from './error.interface'

/** *************> httpResponseError.ts
 * Error class to throw when an HTTP error occurs
 */
export default class HttpResponseError extends Error implements ErrorInterface {
  // Error Name
  name: string

  // Error unique Key
  key: string

  // HTTP Status
  status: number

  // Error extra data
  data?: Record<string, unknown>

  // HTTP Headers
  headers?: Record<string, unknown>

  constructor(
    name: string,
    key: string,
    message: string,
    status: number,
    data: Record<string, unknown> | null = null,
    headers: Record<string, unknown> | null = null
  ) {
    super(message)
    this.name = name
    this.key = key
    this.status = status
    if (data) {
      this.data = data
    }
    if (headers) {
      this.headers = headers
    }
  }
}
