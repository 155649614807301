import ErrorInterface from './error.interface'

/** *************> defaultError.ts
 * Standard error Class to throw when any error occurs
 */
export default class DefaultError extends Error implements ErrorInterface {
  // Error Name
  name: string

  // Error unique Key
  key: string

  // Error extra data
  error: unknown

  constructor(name: string, key: string, message: string, error?: unknown) {
    super(message)
    this.name = name
    this.key = key
    this.error = error || ''
  }
}
