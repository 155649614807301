import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { ServiceContext } from '../containers/ServiceProvider'
import IconMSBLong from './icons/IconMSBLong'

const MaintenancePage = (): JSX.Element => {
  const { maintenance: maintenanceService } = useContext(ServiceContext)
  const [remainingTime, setRemainingTime] = useState(0)

  useEffect(() => {
    const next = maintenanceService.nextRefresh
    if (!next) {
      return
    }

    const int = setInterval(() => {
      setRemainingTime(Math.ceil((next - new Date().valueOf()) / 1000))
    }, 100)

    return () => {
      clearInterval(int)
    }
  }, [])

  return (
    <>
      <Grid centered style={{ marginTop: '60px' }}>
        <Helmet>
          <title>Maintenance en cours</title>
          <meta name="description" content="Maintenance en cours" />
        </Helmet>

        <Grid.Column computer="6" mobile="16" tablet="8">
          <Segment attached textAlign="center" raised={false} color={'orange'} padded>
            <IconMSBLong />
            <Header as="h2">Application en cours de maintenance</Header>
            <br />
            {!!remainingTime && <span>Rechargement de la page dans {remainingTime} secondes</span>}
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default MaintenancePage
