import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Service } from '../Service'
import { OidcService } from '../oidc.service'
import { defaultInterceptor, oidcInterceptor } from './interceptors'
import axiosConfig from './axios.config'

/** *************> axios.service.ts
 * Service Instance for Axios
 * AxiosService is a Service class launched by the ServiceProvider
 *
 * HOW TO USE :
 * - Calling the service into a React Component (function) :
 * const axios = useContext(ServiceContext).axios;
 * - Calling the service anywhere else :
 * import axiosService, { AxiosService } from 'services/axios';
 * const service : AxiosService = axiosService();
 *
 * - Methods :
 * -- getAxiosInstance() : Return one Axios instance by name
 */

export class AxiosService implements Service {
  private axiosInstance: { [key: string]: AxiosInstance } = {}

  /**
   * @override init()
   * Initialize Service
   *
   * @return Promise<void>
   */
  init(services: Record<string, Service>): Promise<void> {
    return new Promise<void>((resolve: () => void) => {
      this.initInstance('default', defaultInterceptor, axiosConfig.defaultConfig)

      const oidc: OidcService = services.oidc as OidcService
      if (oidc) {
        this.initInstance('oidc', oidcInterceptor, axiosConfig.oidcConfig, [oidc])
      }
      resolve()
    })
  }

  /**
   * @override run()
   * Run Service
   *
   * @return Promise<void>
   */
  run(): Promise<void> {
    return Promise.resolve()
  }

  /**
   * *> getAxiosInstance()
   * Get one Axios instance by name
   *
   * @param instanceName : string
   * @returns AxiosInstance
   */
  getAxiosInstance(instanceName: string): AxiosInstance {
    return this.axiosInstance[instanceName]
  }

  /**
   * *> initInstance()
   * Initialize one Axios instance
   *
   * @param instanceName : string
   * @param interceptor : Function Interceptor
   * @param config : AxiosRequestConfig
   * @param services : Array. Services to pass to the interceptor
   */
  private initInstance(
    instanceName: string,
    interceptor: (instance: AxiosInstance, ...arg: Service[]) => void,
    config: AxiosRequestConfig,
    services?: Service[]
  ): void {
    this.axiosInstance[instanceName] = this.createInstance(interceptor, config, services || [])
  }

  /**
   * *> createInstance()
   * Create a new Axios instance with a specific interceptor
   *
   * @param interceptor : Function Interceptor
   * @param config : AxiosRequestConfig
   * @param services : Array. Services to pass to the interceptor
   * @return AxiosInstance
   */
  private createInstance(interceptor: (instance: AxiosInstance, ...arg: Service[]) => void, config: AxiosRequestConfig, services: Service[]): AxiosInstance {
    const currentInstance = axios.create(config)
    interceptor(currentInstance, ...services)
    return currentInstance
  }
}

/** * Service Instance Singleton ** */
const axiosService: AxiosService = new AxiosService()
export default (): AxiosService => axiosService
