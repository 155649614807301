import Debug from 'debug'

const debug = Debug('app:utils:LocalStorageUtils')

// Fake localStorage implementation.
// Mimics localStorage, including events.
// It will work just like localStorage, except for the persistant storage part.
debug('LocalStorageUtils')
function fakeLocalStorage(): void {
  let fakeLocalStorageValues: Record<string, string> = {}
  let storage: Storage

  // If Storage exists we modify it to write to our fakeLocalStorage object instead.
  // If Storage does not exist we create an empty object.
  if (window.Storage && window.localStorage) {
    storage = window.Storage.prototype
  } else {
    // We don't bother implementing a fake Storage object
    ;(window.localStorage as any) = {}
    storage = window.localStorage
  }

  // For older IE
  if (!window.location.origin) {
    ;(window.location.origin as any) = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
  }

  function dispatchStorageEvent(key: string | null, newValue: string | null): void {
    // eslint-disable-next-line eqeqeq
    const oldValue = key == null ? null : storage.getItem(key) // `==` to match both null and undefined
    const url = window.location.href.substr(window.location.origin.length)
    const storageEvent: any = document.createEvent('StorageEvent') // For IE, http://stackoverflow.com/a/25514935/1214183

    storageEvent.initStorageEvent('storage', false, false, key, oldValue, newValue, url, null)
    window.dispatchEvent(storageEvent)
  }

  storage.key = (i: any) => {
    const key = Object.keys(fakeLocalStorageValues)[i]
    return typeof key === 'string' ? key : null
  }

  storage.getItem = (key: any) => {
    return typeof fakeLocalStorageValues[key] === 'string' ? fakeLocalStorageValues[key] : null
  }

  storage.setItem = (key: any, value: any) => {
    dispatchStorageEvent(key, value)
    fakeLocalStorageValues[key] = String(value)
  }

  storage.removeItem = (key: any) => {
    dispatchStorageEvent(key, null)
    delete fakeLocalStorageValues[key]
  }

  storage.clear = () => {
    dispatchStorageEvent(null, null)
    fakeLocalStorageValues = {}
  }
}

// Example of how to use it
if (typeof window.localStorage === 'object') {
  // Safari will throw a fit if we try to use window.localStorage.setItem in private browsing mode.
  try {
    window.localStorage.setItem('localStorageTest', '1')
    window.localStorage.removeItem('localStorageTest')
    debug('LocalStorage is available')
  } catch (e) {
    debug('LocalStorage not available. Faking it...')
    fakeLocalStorage()
  }
} else {
  // Use fake localStorage for any browser that does not support it.
  fakeLocalStorage()
}

const isAvailable = (function isAvailableIffe(): boolean {
  const test = 'test'
  try {
    window.localStorage.setItem(test, test)
    window.localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
})()

const LocalStorage = {
  get(key: string): string | null {
    if (isAvailable) {
      return window.localStorage.getItem(key)
    }
    return null
  },

  set(key: string, value: string): void | null {
    if (isAvailable) {
      return window.localStorage.setItem(key, value)
    }

    return null
  },

  remove(key: string): void | null {
    if (isAvailable) {
      return window.localStorage.removeItem(key)
    }

    return null
  },
}

export default LocalStorage
