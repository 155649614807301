import * as ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { Service } from './Service'
import { OidcService } from './oidc.service'
import { UserService } from './user.service'

export class TrackingService implements Service {
  private tracking = false

  async init(services: Record<string, Service>): Promise<void> {
    if (this.shouldTrack()) {
      const { oidc, user: userService } = services as { oidc: OidcService; user: UserService }
      const advancedMatching: any = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
      const options: any = {
        autoConfig: true, // set pixel's autoConfig
      }

      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE ?? '')
      ReactPixel.init(process.env.REACT_APP_PIXEL_ID ?? '', advancedMatching, options)

      if (oidc.isAuthenticated) {
        ReactGA.set({ userId: userService.getUser()._id })
      } else {
        ReactGA.set({ userId: null })
      }

      this.tracking = true
    }
  }

  async run(): Promise<void> {}

  isTracking(): boolean {
    return this.tracking
  }

  logPageView(page: string): void {
    if (!this.tracking) {
      return
    }

    ReactPixel.pageView() // For tracking page view
    ReactGA.pageview(page)
  }

  paymentAdded(): void {
    if (!this.tracking) {
      return
    }
    ReactPixel.track('AddPaymentInfo', null)
  }

  private shouldTrack(): boolean {
    return navigator.userAgent !== 'ReactSnap' && process.env.REACT_APP_NODE_ENV === 'production'
  }
}

const trackingService = new TrackingService()

export default (): TrackingService => trackingService
