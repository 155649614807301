import { ConnectedRouter } from 'connected-react-router'
import Debug from 'debug'
import { Location } from 'history'
import get from 'lodash/get'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { Route } from 'react-router'
import { Redirect, Switch } from 'react-router-dom'
import I18n from 'redux-i18n'
import MediaHoc from './components/MediaHoc'
import NoMatch from './containers/NoMatch'
import store, { historyStore } from './store'
import { LoginContext } from './utils/AuthService'
import { App } from './utils/CodeSplitting'
import { getLanguage, saveRefereer } from './utils/RequiredUtils' // load on demand
import { translations } from './utils/Translations'
import GuidesIndex from './containers/guides/Index'
import CustomIntercom from './components/CustomIntercom'
import OnBoarding from './containers/account/OnBoarding'
import TrackingServiceInstance from './services/tracking.service'
const debug = Debug('app:routes')

let previous: Location
const trackingService = TrackingServiceInstance()
historyStore.listen((location: Location) => {
  if (location.pathname !== previous?.pathname) {
    previous = location
    trackingService.logPageView(location.pathname + location.search)
  }
})

// first load :
trackingService.logPageView(window.location.pathname + window.location.search)
saveRefereer()

const Routes = (): any => {
  const t = (text: any) => text
  const [user, setUser] = useState({})

  const renderAuth = (
    Component: JSX.Element,
    props: any,
    options: { auth?: { condition: boolean; url: string }; noAuth?: { condition: boolean; url: string } } = {}
  ) => {
    let componentToReturn = Component
    if (options.auth && options.auth.condition) {
      componentToReturn = <Redirect to={options.auth.url} />
    }

    return componentToReturn
  }

  return (
    <MediaHoc>
      <Provider store={store}>
        <I18n translations={translations} initialLang={getLanguage()} fallbackLang="en">
          <ConnectedRouter history={historyStore}>
            <LoginContext.Provider value={{ user, setUser, t }}>
              <CustomIntercom>
                <Switch>
                  <Route path="/onBoarding" render={() => <OnBoarding />} />
                  <Route path="/guide" component={(props: any) => renderAuth(<GuidesIndex {...props} />, props)} />
                  <Route
                    path={`/redirect`}
                    render={(props: any) => {
                      debug('query : ', queryString.parse(props.location.search).next_url)
                      return <Redirect to={queryString.parse(props.location.search).next_url as string} />
                    }}
                  />
                  <Route
                    path="/"
                    component={(props: any) =>
                      renderAuth(<App {...props} />, props, {
                        auth: {
                          condition:
                            get(store.getState(), 'user.data') &&
                            !!get(store.getState(), 'user.data.company.branded_for') &&
                            get(store.getState(), 'user.data.company.branded_for') === 'illicopro' &&
                            get(store.getState(), 'user.data.company.branded_for') === 'matera' &&
                            !get(store.getState(), 'user.data.company.credit_card_exists'),
                          url: '/app/setup/1',
                        },
                      })
                    }
                  />
                  <Route component={NoMatch} />
                </Switch>
              </CustomIntercom>
            </LoginContext.Provider>
          </ConnectedRouter>
        </I18n>
      </Provider>
    </MediaHoc>
  )
}

export default Routes
