import React, { useEffect, useState } from 'react'
import MaintenanceServiceInstance, { MaintenanceService } from '../services/maintenance.service'
import OidcServiceInstance, { OidcService } from '../services/oidc.service'
import { axiosService as AxiosServiceInstance, AxiosService } from '../services/axios'
import UserServiceInstance, { UserService } from '../services/user.service'
import TrackingServiceInstance, { TrackingService } from '../services/tracking.service'
import { Service } from '../services/Service'
import MaintenancePage from '../components/MaintenancePage'
import LoginPolicyErrorPage from '../components/LoginPolicyErrorPage'

/** *************> ServiceProvider.tsx
 * Complete services handler HOC, used as first order component in React App
 *
 * The lifecycle of the Service Provider is :
 * - Initialize and run all services configured
 * - Define the app as loaded
 * - Render the ServiceContext Provider (React Context API)
 * - Render the entire app as a child React component
 */

/**
 * Available services list
 * Add any extra services you need into this Object to launch them into the Service Provider
 * All services loaded by the Service Provider must implement the Service interface
 */

export type Services = Record<string, Service> & {
  maintenance: MaintenanceService
  oidc: OidcService
  axios: AxiosService
  user: UserService
  tracking: TrackingService
}

// Services instances
const servicesInstances: Services = {
  maintenance: MaintenanceServiceInstance(),
  oidc: OidcServiceInstance(),
  axios: AxiosServiceInstance(),
  user: UserServiceInstance(),
  tracking: TrackingServiceInstance(),
}

/** * Service Context ** */
export const ServiceContext = React.createContext<Services>(servicesInstances)

interface ServiceProviderProps {
  children: React.ReactElement | React.ReactElement[]
}

/** * ServiceProvider React Component ** */
export default function ServiceProvider({ children }: ServiceProviderProps): JSX.Element {
  const [appLoaded, setAppLoaded] = useState<boolean>(false)
  const [services] = useState<Services>(servicesInstances)
  const [startUpLoopInterval, setStartUpLoopInterval] = useState<number>(0)
  const [loginError, setLoginError] = useState<boolean>(false)

  const updateData = () => {
    setLoginError(!!services.oidc.loginPolicyError)
  }

  useEffect(() => {
    setStartUpLoopInterval(setInterval(updateData, 100) as unknown as number)
    if (appLoaded) {
      clearInterval(startUpLoopInterval)
    }

    return () => clearInterval(startUpLoopInterval)
  }, [appLoaded])

  const runServices = async (configuration: Services): Promise<void> => {
    const loadedServices: Record<string, Service> = {}

    /* eslint-disable no-await-in-loop, no-restricted-syntax */
    for (const [serviceName, serviceConf] of Object.entries<Service>(configuration)) {
      await serviceConf.init(loadedServices)
      await serviceConf.run()
      if (serviceConf instanceof MaintenanceService && serviceConf.enabled) {
        return
      }

      loadedServices[serviceName] = serviceConf
    }
  }

  useEffect(() => {
    runServices(services)
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setAppLoaded(true)
        return false
      })
      .catch((err: unknown) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }, [])

  return (
    <div>
      {services.maintenance.enabled ? (
        <MaintenancePage />
      ) : loginError ? (
        <LoginPolicyErrorPage />
      ) : !appLoaded || services.oidc.isWaitingForCodeClear ? (
        <div>
          <span className="fetchMeLoader" />
        </div>
      ) : (
        <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
      )}
    </div>
  )
}
