import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { OidcService } from '../../oidc.service'
import { Service } from '../../Service'
import get from 'lodash/get'

/** *************> oidcInterceptor.ts
 * An interceptor is an extra process added to an Axios Instance
 * OIDC Interceptor adds Authorization Header to API Requests
 */

interface RequestHeaders {
  Authorization?: string
}

/**
 * *> oidcInterceptor()
 * Function Interceptor for Identity Providers with OpenID Connect protocol
 *
 * @param instance : AxiosInstance
 * @param oidc : OidcService
 */
export default function oidcInterceptor(instance: AxiosInstance, oidc: Service): void {
  // *> Define interceptor for the request
  instance.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
    const token = await (oidc as OidcService).getToken()

    // *> Add Authorization Header
    const headers = requestConfig.headers as RequestHeaders
    headers.Authorization = token ? `Bearer ${token}` : ''
    return requestConfig
  })
  instance.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response
    },
    async (error: unknown) => {
      if (get(error, 'response.status') === 401) {
        // Unauthorized mean token no authentication anymore, should log out
        await (oidc as OidcService).logout()
      }

      return Promise.reject(error)
    }
  )
}
